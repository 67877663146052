@charset "UTF-8";
/****** UTILS ******/ /*** default values ***/
/***** custom values *****/
/***** Grid Values *****/
/** Extends **/
@import url("https://fonts.googleapis.com/css?family=Mada:400,500,600,700&amp;display=swap");
.error-page .main-wrapper,
.css-equal-content,
.css-equal-heights {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.css-equal-content {
  color: red;
  float: left;
}

.attach-file,
.login-wrapper .loginbox .login-right,
.align-center {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.error-page .main-wrapper,
.login-wrapper .loginbox .login-left,
.dash-widget-icon,
.avatar .avatar-title {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.dash-widget-icon,
.avatar .avatar-title {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.avatar-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.avatar .avatar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.onoffswitch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/******* BASE *******/
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(./fonts/MaterialIcons-Regular.html) format("woff2"),
    url(./fonts/MaterialIcons-Regular.woff) format("woff"),
    url(./fonts/MaterialIcons-Regular.ttf) format("truetype");
}
.primary {
  background-color: #00d0f1;
}

html {
  height: 100%;
}

body {
  background-color: #f8f9fa;
  color: #333333;
  font-family: "Mada", sans-serif;
  font-size: 1rem;
  height: 100%;
  line-height: 1.5;
  overflow-x: hidden;
}
@media (max-width: 767.98px) {
  body {
    font-size: 15px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Mada", sans-serif;
  margin-top: 0;
}

a {
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
  text-decoration: none;
}
a:hover,
a:focus,
a:active {
  outline: none;
  text-decoration: none;
  color: #00d0f1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.form-control {
  border: 1px solid #dddddd;
  box-shadow: none;
  color: #333333;
  font-size: 15px;
  height: 40px;
}
.form-control:focus {
  border-color: #00d0f1;
  box-shadow: none;
  outline: 0 none;
}
.form-control.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

a {
  color: #0dd9f9;
}

input,
button {
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
input:focus,
input:hover,
button:focus,
button:hover {
  outline: none;
}

input[type="file"] {
  height: auto;
  min-height: calc(1.5em + 0.75rem + 2px);
}
input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea.form-control {
  resize: vertical;
}

.navbar-nav > li {
  float: left;
}

.form-group {
  margin-bottom: 1.25rem;
}

.input-group .form-control {
  height: 40px;
}

.nav .open a,
.nav .open a:hover,
.nav .open a:focus {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

.font-weight-600 {
  font-weight: 600;
}

@media (max-width: 767.98px) {
  h1,
  .h1 {
    font-size: 32px;
  }
}

@media (max-width: 767.98px) {
  h2,
  .h2 {
    font-size: 28px;
  }
}

@media (max-width: 767.98px) {
  h3,
  .h3 {
    font-size: 24px;
  }
}

@media (max-width: 767.98px) {
  h4,
  .h4 {
    font-size: 18px;
  }
}

@media (max-width: 767.98px) {
  h5,
  .h5 {
    font-size: 16px;
  }
}

@media (max-width: 767.98px) {
  h6,
  .h6 {
    font-size: 14px;
  }
}

p:last-child  {
  margin-bottom: 0;
}

/*** default values ***/
/***** custom values *****/
/***** Grid Values *****/
/** Extends **/
.error-page .main-wrapper,
.css-equal-heights,
.css-equal-content {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.css-equal-content {
  color: red;
  float: left;
}

.attach-file,
.login-wrapper .loginbox .login-right,
.align-center {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.error-page .main-wrapper,
.login-wrapper .loginbox .login-left,
.dash-widget-icon,
.avatar .avatar-title {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.dash-widget-icon,
.avatar .avatar-title {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.avatar-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.avatar .avatar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.onoffswitch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.col {
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  position: relative;
  min-height: 1px;
}
@media (min-width: 320px) {
  .col.col-xs-12 {
    width: 100%;
  }
  .col.col-xs-11 {
    width: 91.66666667%;
  }
  .col.col-xs-10 {
    width: 83.33333333%;
  }
  .col.col-xs-9 {
    width: 75%;
  }
  .col.col-xs-8 {
    width: 66.66666667%;
  }
  .col.col-xs-7 {
    width: 58.33333333%;
  }
  .col.col-xs-6 {
    width: 50%;
  }
  .col.col-xs-5 {
    width: 41.66666667%;
  }
  .col.col-xs-4 {
    width: 33.33333333%;
  }
  .col.col-xs-3 {
    width: 25%;
  }
  .col.col-xs-2 {
    width: 16.66666667%;
  }
  .col.col-xs-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 568px) {
  .col.col-sm-12 {
    width: 100%;
  }
  .col.col-sm-11 {
    width: 91.66666667%;
  }
  .col.col-sm-10 {
    width: 83.33333333%;
  }
  .col.col-sm-9 {
    width: 75%;
  }
  .col.col-sm-8 {
    width: 66.66666667%;
  }
  .col.col-sm-7 {
    width: 58.33333333%;
  }
  .col.col-sm-6 {
    width: 50%;
  }
  .col.col-sm-5 {
    width: 41.66666667%;
  }
  .col.col-sm-4 {
    width: 33.33333333%;
  }
  .col.col-sm-3 {
    width: 25%;
  }
  .col.col-sm-2 {
    width: 16.66666667%;
  }
  .col.col-sm-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 768px) {
  .col.col-md-12 {
    width: 100%;
  }
  .col.col-md-11 {
    width: 91.66666667%;
  }
  .col.col-md-10 {
    width: 83.33333333%;
  }
  .col.col-md-9 {
    width: 75%;
  }
  .col.col-md-8 {
    width: 66.66666667%;
  }
  .col.col-md-7 {
    width: 58.33333333%;
  }
  .col.col-md-6 {
    width: 50%;
  }
  .col.col-md-5 {
    width: 41.66666667%;
  }
  .col.col-md-4 {
    width: 33.33333333%;
  }
  .col.col-md-3 {
    width: 25%;
  }
  .col.col-md-2 {
    width: 16.66666667%;
  }
  .col.col-md-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 1024px) {
  .col.col-lg-12 {
    width: 100%;
  }
  .col.col-lg-11 {
    width: 91.66666667%;
  }
  .col.col-lg-10 {
    width: 83.33333333%;
  }
  .col.col-lg-9 {
    width: 75%;
  }
  .col.col-lg-8 {
    width: 66.66666667%;
  }
  .col.col-lg-7 {
    width: 58.33333333%;
  }
  .col.col-lg-6 {
    width: 50%;
  }
  .col.col-lg-5 {
    width: 41.66666667%;
  }
  .col.col-lg-4 {
    width: 33.33333333%;
  }
  .col.col-lg-3 {
    width: 25%;
  }
  .col.col-lg-2 {
    width: 16.66666667%;
  }
  .col.col-lg-1 {
    width: 8.33333333%;
  }
}

/******* COMPONENTS ******/
.check {
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  margin: 0;
  padding: 0;
}
.check:checked + .checktoggle {
  background-color: #55ce63;
}
.check:checked + .checktoggle:after {
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
  -webkit-transform: translate(calc(-100% - 5px), -50%);
  -ms-transform: translate(calc(-100% - 5px), -50%);
}

.checktoggle {
  background-color: #e0001a;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 48px;
  border-radius: 12px;
}
.checktoggle:after {
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  transition: left 300ms ease, transform 300ms ease;
  transform: translate(5px, -50%);
  -webkit-transform: translate(5px, -50%);
  -ms-transform: translate(5px, -50%);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
}

.onoffswitch {
  margin-left: auto;
  position: relative;
  width: 73px;
}

.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 0;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  margin-left: -100%;
  -webkit-transition: margin 0.3s ease-in 0s;
  -ms-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
  width: 200%;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  box-sizing: border-box;
  color: #ffffff;
  display: block;
  float: left;
  font-size: 16px;
  height: 30px;
  line-height: 32px;
  width: 50%;
  padding: 0;
}
.onoffswitch-inner:before {
  background-color: #55ce63;
  color: #ffffff;
  content: "ON";
  padding-left: 14px;
}
.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 14px;
  background-color: #cccccc;
  color: #ffffff;
  text-align: right;
}

.onoffswitch-switch {
  background: #ffffff;
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-in 0s;
  -ms-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  margin: 5px;
  position: absolute;
  top: 0;
  right: 43px;
  bottom: 0;
}

.btn {
  border: 0;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border: 0;
}

.card-header-tabs {
  border-bottom: 0;
}

.nav-tabs {
  border-bottom: 1px solid #e6e6e6;
}
.nav-tabs > li > a {
  margin-right: 0;
  color: #888888;
  border-radius: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: transparent;
  color: #333333;
}
.nav-tabs .nav-tabs-solid > li > a {
  color: #333333;
}
.nav-tabs .nav-tabs-solid > .active > a,
.nav-tabs .nav-tabs-solid > .active > a:hover,
.nav-tabs .nav-tabs-solid > .active > a:focus {
  background-color: #00d0f1;
  border-color: #00d0f1;
  color: #ffffff;
}
.nav-tabs .nav-link {
  border-radius: 0;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  background-color: #eeeeee;
  border-color: transparent;
  color: #333333;
}
.nav-tabs.nav-justified > li > a {
  margin-bottom: 0;
  border-radius: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: #dddddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: 1px solid #dddddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-solid {
  background-color: #fafafa;
  border: 0;
}
.nav-tabs.nav-tabs-solid > li {
  margin-bottom: 0;
}
.nav-tabs.nav-tabs-solid > li > a {
  color: #333333;
  border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
  background-color: #f5f5f5;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
  background-color: #00d0f1;
  border-color: #00d0f1;
  color: #ffffff;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
  background-color: #f5f5f5;
  border-color: transparent;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
  border-top-color: #00d0f1;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li.open > a:hover,
.nav-tabs.nav-tabs-top > li.open > a:focus {
  border-top-color: #dddddd;
}
.nav-tabs.nav-tabs-top > li + li > a {
  margin-left: 1px;
}
.nav-tabs.nav-tabs-bottom > li {
  margin-bottom: -1px;
}
.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: #00d0f1;
  background-color: transparent;
  -webkit-transition: none 0s ease 0s;
  -ms-transition: none 0s ease 0s;
  transition: none 0s ease 0s;
}

.card-header-tabs {
  border-bottom: 0;
}

.tab-content {
  padding-top: 20px;
}

.nav-tabs-justified > li > a {
  margin-bottom: 0;
  border-radius: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
  border-bottom-color: #dddddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
  border-bottom: 1px solid #dddddd;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

/****** LAYOUT ******/
.main-wrapper {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
}

.page-wrapper {
  margin-left: 240px;
  padding-top: 60px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media (max-width: 991.98px) {
  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
}
.page-wrapper > .content {
  padding: 1.875rem 1.875rem 0;
}
@media (max-width: 575.98px) {
  .page-wrapper > .content {
    padding: 0.9375rem 0.9375rem 0;
  }
}

.page-header {
  margin-bottom: 1.875rem;
}
@media (max-width: 575.98px) {
  .page-header {
    margin-bottom: 0.9375rem;
  }
}
.page-header .breadcrumb {
  background-color: transparent;
  color: #6c757d;
  font-size: 16px;
  font-weight: 500;
  margin: auto auto 0;
  padding: 0;
}
@media (max-width: 479px) {
  .page-header .breadcrumb {
    display: none;
  }
}
.page-header .breadcrumb a {
  color: #333333;
}

.page-title {
  color: #333333;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .mini-sidebar .header .header-left {
    padding: 0 5px;
    width: 78px;
  }
}
@media (min-width: 992px) {
  .mini-sidebar .header .header-left .logo {
    display: none;
  }
}
@media (min-width: 992px) {
  .mini-sidebar .header .header-left .logo img {
    height: auto;
    max-height: 40px;
    width: auto;
  }
}
@media (min-width: 992px) {
  .mini-sidebar .header .header-left .logo.logo-small {
    display: block;
  }
}

.header {
  background: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  z-index: 1001;
  height: 60px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}
.header .header-left {
  float: left;
  height: 60px;
  position: relative;
  text-align: center;
  width: 240px;
  z-index: 1;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 0 20px;
}
@media (max-width: 991.98px) {
  .header .header-left {
    position: absolute;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .header .header-left {
    padding: 0 15px;
  }
}
.header .header-left .logo {
  display: inline-block;
  line-height: 60px;
}
@media (max-width: 767.98px) {
  .header .header-left .logo {
    display: none;
  }
}
.header .header-left .logo img {
  max-height: 40px;
  width: auto;
}
.header .header-left .logo.logo-small {
  display: none;
}
@media (max-width: 767.98px) {
  .header .header-left .logo.logo-small {
    display: inline-block;
  }
}
.header .dropdown-menu > li > a {
  position: relative;
  padding: 10px 18px;
}
.header .dropdown-menu > li > a:hover,
.header .dropdown-menu > li > a:focus {
  background-color: #00d0f1;
  color: #ffffff;
}
.header .dropdown-menu > li > a:hover i,
.header .dropdown-menu > li > a:focus i {
  color: #ffffff;
}
.header .dropdown-menu > li > a i {
  color: #00d0f1;
  margin-right: 10px;
  text-align: center;
  width: 18px;
}
.header .dropdown-toggle:after {
  display: none;
}
.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #333333;
  border-right: 2px solid #333333;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  transform-origin: 66% 66%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
}
@media (max-width: 767.98px) {
  .header .has-arrow .dropdown-toggle:after {
    display: none;
  }
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
}

.user-menu {
  float: right;
  position: relative;
  z-index: 99;
  margin: 0;
}
.user-menu.nav > li > a {
  color: #333333;
  font-size: 14px;
  line-height: 58px;
  height: 60px;
  padding: 0 15px;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
  color: #333333;
}
.user-menu.nav > li > a i {
  font-size: 24px;
  line-height: 60px;
}
.user-menu.nav > li > a .badge {
  background-color: #1b5a90;
  display: block;
  font-size: 10px;
  font-weight: 700;
  min-height: 15px;
  min-width: 15px;
  color: #ffffff;
  position: absolute;
  top: 6px;
  right: 3px;
}
@media (max-width: 767.98px) {
  .user-menu.nav > li > a > span:not(.user-img) {
    display: none;
  }
}
.user-menu .dropdown-menu {
  min-width: 200px;
  padding: 0;
}
.user-menu .dropdown-menu > li > a i {
  color: #00d0f1;
  font-size: 16px;
  margin-right: 10px;
  min-width: 18px;
  text-align: center;
}
.user-menu .dropdown-menu > li > a:hover i,
.user-menu .dropdown-menu > li > a:focus i {
  color: #ffffff;
}
.user-menu .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  border-top: 1px solid #e3e3e3;
  padding: 10px 15px;
}
.user-menu .dropdown-menu .dropdown-item:hover {
  color: #ffffff;
  background: #00d0f1;
}

.user-img {
  display: inline-block;
  margin-right: 3px;
  position: relative;
}
.user-img.nav > li > a .mobile_btn {
  border: 0;
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
.user-img .dropdown-menu {
  min-width: 200px;
  padding: 0;
}
.user-img .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  border-top: 1px solid #e3e3e3;
  padding: 10px 15px;
}
.user-img .dropdown-menu .dropdown-item:hover {
  color: #ffffff;
  background: #00d0f1;
}

.mobile_btn {
  display: none;
  float: left;
}
@media (max-width: 991.98px) {
  .mobile_btn {
    color: #333333 !important;
    cursor: pointer;
    display: block;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    z-index: 10;
    padding: 0 15px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.slide-nav .sidebar {
  margin-left: 0;
}

@media (max-width: 767.98px) {
  .app-dropdown {
    display: none;
  }
}
.app-dropdown .dropdown-menu {
  width: 300px;
  padding: 0;
}

.app-dropdown-menu .app-list {
  padding: 15px;
}
.app-dropdown-menu .app-item {
  border: 1px solid transparent;
  color: #737373;
  display: block;
  text-align: center;
  border-radius: 3px;
  padding: 10px 0;
}
.app-dropdown-menu .app-item i {
  font-size: 20px;
  height: 24px;
}
.app-dropdown-menu .app-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.app-dropdown-menu .app-item:hover,
.app-dropdown-menu .app-item:focus,
.app-dropdown-menu .app-item:active,
.app-dropdown-menu .app-item.active {
  background-color: #f9f9f9;
  border-color: #e3e3e3;
}

.app-list > div + div {
  margin-top: 5px;
}
.app-list > .row {
  margin-left: -5px;
  margin-right: -5px;
}
.app-list > .row > .col {
  padding-left: 5px;
  padding-right: 5px;
}

.user-header {
  background-color: #f9f9f9;
  display: flex;
  padding: 10px 15px;
}
.user-header .user-text {
  margin-left: 10px;
}
.user-header .user-text h6 {
  margin-bottom: 2px;
}

.menu-title {
  color: #a3a3a3;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 0 25px;
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  width: 100%;
  z-index: 999;
  position: fixed;
  top: 60px;
  left: 0;
}
@media (max-width: 991.98px) {
  .sidebar-overlay.opened {
    display: block;
  }
}

html.menu-opened {
  overflow: hidden;
}
html.menu-opened body {
  overflow: hidden;
}

.top-nav-search {
  float: left;
  margin-left: 15px;
}
@media (max-width: 991.98px) {
  .top-nav-search {
    display: none;
  }
}
.top-nav-search form {
  margin-top: 10px;
  position: relative;
  width: 230px;
}
.top-nav-search .form-control {
  border-color: rgba(0, 0, 0, 0.1);
  color: #333333;
  height: 40px;
  border-radius: 50px;
  padding: 10px 50px 10px 15px;
}
.top-nav-search .form-control::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #333333;
  opacity: 1;
}
.top-nav-search .form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #333333;
  opacity: 1;
}
.top-nav-search .form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #333333;
  opacity: 1;
}
.top-nav-search .form-control:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #333333;
  opacity: 1;
}
.top-nav-search .btn {
  background-color: transparent;
  border-color: transparent;
  color: #333333;
  min-height: 40px;
  padding: 7px 15px;
  position: absolute;
  top: 0;
  right: 0;
}
.top-nav-search.active form {
  display: block;
  position: absolute;
  left: 0;
}

@media (min-width: 991.98px) {
  #toggle_btn {
    align-items: center;
    color: #333333;
    display: inline-flex;
    float: left;
    font-size: 30px;
    height: 60px;
    justify-content: center;
    margin-left: 15px;
    padding: 0 15px;
  }
}
@media (max-width: 991.98px) {
  #toggle_btn {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .login-wrapper .loginbox {
    max-width: 450px;
    min-height: unset;
  }
}
@media (max-width: 991.98px) {
  .login-wrapper .loginbox .login-left {
    width: 50%;
    padding: 80px 50px;
  }
}
@media (max-width: 767.98px) {
  .login-wrapper .loginbox .login-left {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .login-wrapper .loginbox .login-right {
    width: 50%;
    padding: 50px;
  }
}
@media (max-width: 767.98px) {
  .login-wrapper .loginbox .login-right {
    float: none;
    width: 100%;
    padding: 1.875rem;
  }
}
@media (max-width: 767.98px) {
  .login-wrapper .loginbox .login-right h1 {
    font-size: 22px;
  }
}

@media (max-width: 991.98px) {
  .navbar-nav .open .dropdown-menu {
    float: left;
    position: absolute;
  }
}
@media (max-width: 767.98px) {
  .navbar-nav .open .dropdown-menu {
    float: left;
    position: absolute;
  }
}
@media (max-width: 991.98px) {
  .navbar-nav.user-menu .open .dropdown-menu {
    left: auto;
    right: 0;
  }
}
@media (max-width: 767.98px) {
  .navbar-nav.user-menu .open .dropdown-menu {
    left: auto;
    right: 0;
  }
}

.table {
  color: #333333;
  max-width: 100%;
  margin-bottom: 0;
  width: 100%;
  --bs-table-bg: transparent;
}
.table > tbody > tr > th {
  border-top: 0;
  padding: 10px 8px;
}
.table.no-border > tbody > tr > td,
.table.no-border > tbody > tr th,
.table.no-border tfoot > tr > td,
.table.no-border tfoot > tr th {
  border-top: 0;
  padding: 10px 8px;
}
.table.dataTable {
  border-collapse: collapse !important;
}
.table thead {
  background-color: #f8f9fa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.table thead tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table thead tr:last-child {
  border-color: transparent;
}
.table thead tr th {
  font-weight: 600;
  border: 0;
}
.table.table-center th,
.table.table-center td {
  vertical-align: middle;
}
.table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr:last-child {
  border-color: transparent;
}

.table-striped > thead > tr:nth-of-type(2n + 1) {
  background-color: #f8f9fa;
}
.table-striped thead tr {
  border-color: transparent;
}
.table-striped tbody tr {
  border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(235, 235, 235, 0.4);
}

.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
}

table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}
table.table td h2.table-avatar {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  vertical-align: middle;
  white-space: nowrap;
  margin: 0;
  padding: 0;
}
table.table td h2 a {
  color: #333333;
}
table.table td h2 a:hover {
  color: #00d0f1;
}
table.table td h2 span {
  color: #888888;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}

.table-hover tbody tr:hover {
  background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
  color: #474648;
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
  border-color: rgba(0, 0, 0, 0.05);
}

.card-table .card-body {
  padding: 0;
}
.card-table .card-body .table td,
.card-table .card-body .table th {
  border-top: 1px solid #e2e5e8;
  white-space: nowrap;
  padding: 1rem 0.75rem;
}
.card-table .card-body .table > thead > tr > th {
  border-top: 0;
}
.card-table .card-body .table tr th:first-child,
.card-table .card-body .table tr td:first-child {
  padding-left: 1.5rem;
}
.card-table .card-body .table tr th:last-child,
.card-table .card-body .table tr td:last-child {
  padding-right: 1.5rem;
}

#delete_modal .modal-content {
  text-align: center;
}

.table-responsive {
  white-space: nowrap;
}

.dataTables_length {
  margin-bottom: 10px;
}

.table-space {
  width: 100px;
}

table.dataTable thead .sorting_asc:after {
  top: 6px;
}
table.dataTable thead .sorting_asc:before {
  top: 6px;
}

table.dataTable thead .sorting_desc:before {
  top: 6px;
}
table.dataTable thead .sorting_desc:after {
  top: 6px;
}

table.dataTable thead .sorting:before {
  top: 6px;
}
table.dataTable thead .sorting:after {
  top: 6px;
}

.btn.focus,
.btn:focus {
  box-shadow: unset;
}
.btn.btn-rounded {
  border-radius: 50px;
}

.btn-white {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  color: #272b41;
}

.bg-primary,
.badge-primary {
  background-color: #00d0f1 !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #19c1dc !important;
}

.bg-success,
.badge-success {
  background-color: #7bb13c !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #699834 !important;
}

.bg-info,
.badge-info {
  background-color: #009efb !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #028ee1 !important;
}

.bg-warning,
.badge-warning {
  background-color: #ffbc34 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #e9ab2e !important;
}

.bg-danger,
.badge-danger {
  background-color: #e84646 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #e63333 !important;
}

.bg-white {
  background-color: #ffffff;
}

.bg-purple,
.badge-purple {
  background-color: #9368e9 !important;
}

.text-primary {
  color: #00d0f1 !important;
}

.dropdown-menu li > a.text-primary {
  color: #00d0f1 !important;
}

.text-success {
  color: #699834 !important;
}

.dropdown-menu li > a.text-success {
  color: #699834 !important;
}

.text-danger {
  color: #e84646 !important;
}

.dropdown-menu li > a.text-danger {
  color: #e84646 !important;
}

.text-info {
  color: #009efb !important;
}

.dropdown-menu li > a.text-info {
  color: #009efb !important;
}

.text-warning {
  color: #ffbc34 !important;
}

.dropdown-menu li > a.text-warning {
  color: #ffbc34 !important;
}

.text-purple {
  color: #7460ee !important;
}

.dropdown-menu li > a.text-purple {
  color: #7460ee !important;
}

.text-muted {
  color: #757575 !important;
}

.btn-primary {
  background-color: #00d0f1;
  border: 1px solid #00d0f1;
}
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active {
  color: #ffffff;
  background-color: #19c1dc;
  border-color: #19c1dc;
}
.btn-primary:focus .open > .dropdown-toggle.btn-primary,
.btn-primary:hover .open > .dropdown-toggle.btn-primary,
.btn-primary:active .open > .dropdown-toggle.btn-primary,
.btn-primary.active .open > .dropdown-toggle.btn-primary {
  background-color: #19c1dc;
  border: 1px solid #19c1dc;
}
.btn-primary.active.focus .open > .dropdown-toggle.btn-primary.focus,
.btn-primary.active.focus .open > .dropdown-toggle.btn-primary:focus,
.btn-primary.active.focus .open > .dropdown-toggle.btn-primary:hover,
.btn-primary.active:focus .open > .dropdown-toggle.btn-primary.focus,
.btn-primary.active:focus .open > .dropdown-toggle.btn-primary:focus,
.btn-primary.active:focus .open > .dropdown-toggle.btn-primary:hover,
.btn-primary.active:hover .open > .dropdown-toggle.btn-primary.focus,
.btn-primary.active:hover .open > .dropdown-toggle.btn-primary:focus,
.btn-primary.active:hover .open > .dropdown-toggle.btn-primary:hover,
.btn-primary .focus.focus .open > .dropdown-toggle.btn-primary.focus,
.btn-primary .focus.focus .open > .dropdown-toggle.btn-primary:focus,
.btn-primary .focus.focus .open > .dropdown-toggle.btn-primary:hover,
.btn-primary .focus:focus .open > .dropdown-toggle.btn-primary.focus,
.btn-primary .focus:focus .open > .dropdown-toggle.btn-primary:focus,
.btn-primary .focus:focus .open > .dropdown-toggle.btn-primary:hover,
.btn-primary .focus:hover .open > .dropdown-toggle.btn-primary.focus,
.btn-primary .focus:hover .open > .dropdown-toggle.btn-primary:focus,
.btn-primary .focus:hover .open > .dropdown-toggle.btn-primary:hover {
  background-color: #19c1dc;
  border: 1px solid #19c1dc;
}
.btn-primary:active:focus,
.btn-primary:active:hover {
  background-color: #19c1dc;
  border: 1px solid #19c1dc;
}
.btn-primary .active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled) {
  background-color: #19c1dc;
  border-color: #19c1dc;
  color: #ffffff;
}
.btn-primary .active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}
.btn-primary .show > .btn-primary.dropdown-toggle {
  background-color: #19c1dc;
  border-color: #19c1dc;
  color: #ffffff;
}
.btn-primary .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #00d0f1;
  border-color: #00d0f1;
  color: #ffffff;
}

.btn-secondary.active:focus :not(:disabled):not(.disabled),
.btn-secondary:active:focus :not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}
.show > .btn-success.dropdown-toggle {
  background-color: #699834;
  border-color: #699834;
  color: #ffffff;
}
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: unset;
}
.show > .btn-info.dropdown-toggle {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #ffffff;
}
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: unset;
}
.show > .btn-warning.dropdown-toggle {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #ffffff;
}
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: unset;
}
.show > .btn-danger.dropdown-toggle {
  background-color: #e63333;
  border-color: #e63333;
  color: #ffffff;
}
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: unset;
}
.show > .btn-light.dropdown-toggle:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: unset;
}
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #00d0f1;
  border-color: #00d0f1;
}
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.show > .btn-outline-success.dropdown-toggle {
  background-color: #7bb13c;
  border-color: #7bb13c;
}
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}
.show > .btn-outline-info.dropdown-toggle {
  background-color: #009efb;
  border-color: #009efb;
}
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}
.show > .btn-outline-danger.dropdown-toggle {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success {
  background-color: #7bb13c;
  border: 1px solid #7bb13c;
}
.btn-success:hover,
.btn-success:focus .btn-success.active,
.btn-success:active,
.btn-success .open__ > .dropdown-toggle.btn-sccess {
  background-color: #699834;
  border: 1px solid #699834;
  color: #ffffff;
}
.btn-success .open > .dropdown-toggle.btn-success {
  background-color: #699834;
  border: 1px solid #699834;
  color: #ffffff;
}
.btn-success .open > .dropdown-toggle.btn-success.focus,
.btn-success .open > .dropdown-toggle.btn-success:hover,
.btn-success .open > .dropdown-toggle.btn-success:focus {
  background-color: #699834;
  border: 1px solid #699834;
}
.btn-success.active:focus,
.btn-success.active.focus,
.btn-success.active:hover,
.btn-success.focus,
.btn-success:active:focus,
.btn-success:active:hover {
  background-color: #699834;
  border: 1px solid #699834;
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled) {
  background-color: #699834;
  border-color: #699834;
  color: #ffffff;
}
.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}
.btn-success.disabled,
.btn-success:disabled {
  background-color: #7bb13c;
  border-color: #7bb13c;
  color: #ffffff;
}

.btn-info {
  background-color: #009efb;
  border: 1px solid #009efb;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}
.btn-info.active:focus,
.btn-info.active.focus,
.btn-info.active:hover,
.btn-info.focus,
.btn-info:active:focus,
.btn-info:active:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled) {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #ffffff;
}
.btn-info.active:not(:disabled):not(.disabled):focus,
.btn-info:active:not(:disabled):not(.disabled):focus {
  box-shadow: unset;
}
.btn-info.disabled,
.btn-info:disabled {
  background-color: #009efb;
  border-color: #009efb;
  color: #ffffff;
}

.btn-warning {
  background-color: #ffbc34;
  border: 1px solid #ffbc34;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.btn-warning.active:focus,
.btn-warning.active.focus,
.btn-warning.active:hover,
.btn-warning.focus,
.btn-warning:active:focus,
.btn-warning:active:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled) {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #ffffff;
}
.btn-warning.active:not(:disabled):not(.disabled):focus,
.btn-warning:active:not(:disabled):not(.disabled):focus {
  box-shadow: unset;
}
.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffbc34;
  border-color: #ffbc34;
  color: #ffffff;
}

.btn-danger {
  background-color: #e84646;
  border: 1px solid #e84646;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active {
  background-color: #e63333;
  border: 1px solid #e63333;
}
.btn-danger.active:focus,
.btn-danger.active.focus,
.btn-danger.active:hover,
.btn-danger.focus,
.btn-danger:active:focus,
.btn-danger:active:hover {
  background-color: #e63333;
  border: 1px solid #e63333;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled) {
  background-color: #e63333;
  border-color: #e63333;
  color: #ffffff;
}
.btn-danger.active:not(:disabled):not(.disabled):focus,
.btn-danger:active:not(:disabled):not(.disabled):focus {
  box-shadow: unset;
}
.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #e84646;
  border-color: #e84646;
  color: #ffffff;
}

.open > .dropdown-toggle.btn-info {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}
.open > .dropdown-toggle.btn-warning {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.open > .dropdown-toggle.btn-danger {
  background-color: #e63333;
  border: 1px solid #e63333;
}
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled) {
  box-shadow: unset;
}

.btn-outline-primary {
  color: #00d0f1;
  border-color: #00d0f1;
}
.btn-outline-primary:hover {
  background-color: #00d0f1;
  border-color: #00d0f1;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: none;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #00d0f1;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #00d0f1;
  border-color: #00d0f1;
}

.btn-outline-success {
  color: #7bb13c;
  border-color: #7bb13c;
}
.btn-outline-success:hover {
  background-color: #7bb13c;
  border-color: #7bb13c;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: none;
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #7bb13c;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active {
  background-color: #7bb13c;
  border-color: #7bb13c;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #009efb;
  border-color: #009efb;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #009efb;
  border-color: #009efb;
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  color: #009efb;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: none;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active {
  background-color: #009efb;
  border-color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #ffbc34;
  border-color: #ffbc34;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  background-color: transparent;
  color: #ffbc34;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: none;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  background-color: #ffbc34;
  border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #e84646;
  border-color: #e84646;
}
.btn-outline-danger:hover {
  color: #212529;
  background-color: #e84646;
  border-color: #e84646;
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  background-color: transparent;
  color: #e84646;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: none;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: #e84646;
  border-color: #e84646;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: #ababab;
  border-color: #e6e6e6;
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #ababab;
}

.pagination {
  --bs-pagination-disabled-bg: transparent;
}
.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  background-color: #20c0f3;
  border-color: #20c0f3;
}
.pagination > .active > a,
.pagination > .active > span {
  color: #ffffff;
}
.pagination > li > a,
.pagination > li > span {
  color: #20c0f3;
}

.page-link:hover {
  color: #20c0f3;
}
.page-link:focus {
  box-shadow: unset;
}

.page-item.active .page-link {
  background-color: #20c0f3;
  border-color: #20c0f3;
}

.dropdown-menu {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inherit;
  font-size: 14px;
  border-radius: 3px;
  transform-origin: left top 0;
  -webkit-transform-origin: left top 0;
  -ms-transform-origin: left top 0;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #0de0fe;
}

.navbar-nav__.open--.dropdown-menu {
  border: 0;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.btn-light {
  border-color: #e6e6e6;
  color: #a6a6a6;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #20c0f3;
  text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: #20c0f3;
}

.bg-info-light {
  background-color: rgba(2, 182, 179, 0.12) !important;
  color: #1db9aa !important;
}

.bg-primary-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}

.bg-danger-light {
  background-color: rgba(242, 17, 54, 0.12) !important;
  color: #e63c3c !important;
}

.bg-warning-light {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}

.bg-success-light {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}

.bg-purple-light {
  background-color: rgba(197, 128, 255, 0.12) !important;
  color: #c580ff !important;
}

.bg-default-light {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}

.text-xs {
  font-size: 12px !important;
}

.text-sm {
  font-size: 14px !important;
}

.text-lg {
  font-size: 18px !important;
}

.text-xl {
  font-size: 24px !important;
}

.form-group {
  margin-bottom: 20px;
}

.card {
  border: 1px solid #f0f0f0;
  margin-bottom: 30px;
}
@media (max-width: 575.98px) {
  .card {
    margin-bottom: 15px;
  }
}
.card .card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
}
.card .card-header .card-title {
  margin-bottom: 0;
}

.card-body {
  padding: 1.5rem;
}
@media (max-width: 575.98px) {
  .card-body {
    padding: 1.25rem;
  }
}

.card-header {
  border-bottom: 1px solid #e6e6e6;
  padding: 1rem 1.5rem;
}
@media (max-width: 575.98px) {
  .card-header {
    padding: 0.75rem 1.25rem;
  }
}

.card-footer {
  background-color: #ffffff;
  border-top: 1px solid #e6e6e6;
  padding: 1rem 1.5rem;
}
@media (max-width: 575.98px) {
  .card-footer {
    padding: 0.75rem 1.25rem;
  }
}

.modal-footer.text-start {
  text-align: left;
}
.modal-footer.text-center {
  text-align: center;
}

.border-primary {
  border-color: #00d0f1 !important;
}

.text-secondary {
  color: #b8bdc1 !important;
}

.page-item.active .page-link {
  background-color: #00d0f1;
  border-color: #00d0f1;
}

@media (max-width: 767.98px) {
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center;
    margin-top: 10px;
  }
  div.dataTables_wrapper div.dataTables_info {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .mini-sidebar.expand-menu .sidebar {
    width: 240px;
  }
}
@media (min-width: 992px) {
  .mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
    display: inline;
    opacity: 1;
  }
}
@media (min-width: 992px) {
  .mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a i {
    font-size: 24px;
    width: 20px;
  }
}
@media (min-width: 992px) {
  .mini-sidebar.expand-menu .menu-title {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .mini-sidebar.expand-menu .menu-title a {
    visibility: hidden;
  }
}
@media (min-width: 992px) {
  .mini-sidebar .sidebar {
    width: 78px;
  }
}
@media (min-width: 992px) {
  .mini-sidebar .sidebar .sidebar-menu ul > li > a span {
    display: none;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 992px) {
  .mini-sidebar .menu-title {
    visibility: hidden;
    white-space: nowrap;
  }
}
@media (min-width: 992px) {
  .mini-sidebar .menu-title a {
    visibility: hidden;
  }
}
@media (min-width: 992px) {
  .mini-sidebar .page-wrapper {
    margin-left: 78px;
  }
}

.sidebar {
  background-color: #1b5a90;
  margin-top: 0;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  width: 240px;
  z-index: 1001;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
}
@media (max-width: 991.98px) {
  .sidebar {
    margin-left: -225px;
    width: 225px;
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1000;
  }
}
.sidebar.opened {
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sidebar .sidebar-menu > ul > li {
  margin-bottom: 3px;
  position: relative;
}
.sidebar .sidebar-menu > ul > li:last-child {
  margin-bottom: 0;
}
.sidebar .sidebar-menu > ul > li > a {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: #f1f1f1;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 3px;
  padding: 8px 15px;
}
.sidebar .sidebar-menu > ul > li > a span {
  display: inline-block;
  margin-left: 10px;
  white-space: nowrap;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
  margin-left: auto;
}
.sidebar .sidebar-menu .menu-arrow {
  display: inline-block;
  font-family: "FontAwesome";
  text-rendering: auto;
  line-height: 40px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 18px;
  -webkit-transition: transform 0.15s;
  -ms-transition: transform 0.15s;
  transition: transform 0.15s;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  position: absolute;
  top: 11px;
  right: 15px;
}
.sidebar .sidebar-menu .menu-arrow:before {
  content: "\f105";
}
.sidebar ul ul {
  display: none;
}
.sidebar ul ul a {
  display: block;
  font-size: 15px !important;
  padding: 7px 10px 7px 45px !important;
  position: relative;
}
.sidebar ul ul a .menu-arrow {
  top: 10px !important;
}
.sidebar ul ul ul a {
  padding-left: 65px !important;
}
.sidebar ul ul ul ul a {
  padding-left: 85px !important;
}
.sidebar ul li a.subdrop .menu-arrow {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}
.sidebar ul li a i {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  vertical-align: middle;
  width: 20px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar ul li a > .badge {
  color: #ffffff;
}
.sidebar ul li.menu-title a i {
  font-size: 16px !important;
  margin-right: 0;
  text-align: right;
  width: auto;
}

.sidebar-inner {
  height: 100%;
  min-height: 100%;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu {
  padding: 15px;
}
.sidebar-menu ul {
  font-size: 15px;
  list-style-type: none;
  position: relative;
  margin: 0;
  padding: 0;
}
.sidebar-menu ul li a {
  color: #ffffff;
  display: block;
  font-size: 16px;
  height: auto;
  padding: 0 20px;
}
.sidebar-menu ul li a:hover {
  color: #5ae8ff;
}
.sidebar-menu ul li.active a {
  background-color: #00d0f1;
  color: #fff;
}
.sidebar-menu ul ul a.active {
  color: #20e3ff;
  text-decoration: underline;
}
.sidebar-menu > ul > li > a:hover {
  background-color: #19c1dc;
  color: #ffffff;
}
.sidebar-menu > ul > li.active > a:hover {
  background-color: #19c1dc;
  color: #ffffff;
}

.menu-title {
  color: #ebebeb;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  opacity: 1;
  white-space: nowrap;
  padding: 5px 15px;
}
.menu-title > i {
  float: right;
  line-height: 40px;
}
.menu-title li.menu-title a {
  color: #ff9b44;
  display: inline-block;
  margin-left: auto;
  padding: 0;
}
.menu-title li.menu-title a .btn {
  color: #ffffff;
  display: block;
  float: none;
  font-size: 15px;
  margin: auto auto 15px;
  padding: 10px 15px;
}

@media (min-width: 992px) {
  .modal-open.mini-sidebar .sidebar {
    z-index: 1051;
  }
}

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}
.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.avatar .avatar-title {
  width: 100%;
  height: 100%;
  background-color: #00d0f1;
  color: #ffffff;
}
.avatar.avatar-online::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #7bb13c;
}
.avatar.avatar-offline::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #e84646;
}
.avatar.avatar-away::before {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #ffbc34;
}
.avatar .border {
  border-width: 3px !important;
}
.avatar .rounded {
  border-radius: 6px !important;
}
.avatar .avatar-title {
  font-size: 18px;
}
.avatar .avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}
.avatar .avatar-xs .border {
  border-width: 2px !important;
}
.avatar .avatar-xs .rounded {
  border-radius: 4px !important;
}
.avatar .avatar-xs .avatar-title {
  font-size: 12px;
}
.avatar .avatar-xs.avatar-away::before,
.avatar .avatar-xs.avatar-offline::before,
.avatar .avatar-xs.avatar-online::before {
  border-width: 1px;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}
.avatar-sm .border {
  border-width: 3px !important;
}
.avatar-sm .rounded {
  border-radius: 4px !important;
}
.avatar-sm .avatar-title {
  font-size: 15px;
}
.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
  border-width: 2px;
}

.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}
.avatar-lg .border {
  border-width: 3px !important;
}
.avatar-lg .rounded {
  border-radius: 8px !important;
}
.avatar-lg .avatar-title {
  font-size: 24px;
}
.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
  border-width: 3px;
}

.avatar-xl {
  width: 5rem;
  height: 5rem;
}
.avatar-xl .border {
  border-width: 4px !important;
}
.avatar-xl .rounded {
  border-radius: 8px !important;
}
.avatar-xl .avatar-title {
  font-size: 28px;
}
.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
  border-width: 4px;
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}
@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }
}
.avatar-xxl .border {
  border-width: 6px !important;
}
@media (min-width: 768px) {
  .avatar-xxl .border {
    border-width: 6px !important;
  }
}
.avatar-xxl .rounded {
  border-radius: 8px !important;
}
@media (min-width: 768px) {
  .avatar-xxl .rounded {
    border-width: 12px !important;
  }
}
.avatar-xxl .avatar-title {
  font-size: 30px;
}
@media (min-width: 768px) {
  .avatar-xxl .avatar-title {
    font-size: 42px;
  }
}
.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
  border-width: 4px;
}
@media (min-width: 768px) {
  .avatar-xxl.avatar-away::before,
  .avatar-xxl.avatar-offline::before,
  .avatar-xxl.avatar-online::before {
    border-width: 4px;
  }
}

.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}
.avatar-group .avatar:hover {
  z-index: 1;
}
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.40625rem;
}
.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}

.avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

.select2-results__option {
  padding: 6px 15px;
}

.select2-container .select2-selection--single {
  border: 1px solid #dddddd;
  height: 40px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 15px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 38px;
  right: 7px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #dcdcdc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  margin-left: -10px;
  margin-top: -2px;
  width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #272b41;
  font-size: 15px;
  font-weight: normal;
  line-height: 38px;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #dcdcdc;
  border-width: 0 6px 6px;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #20c0f3;
}
.select2-container--default .select2-selection--multiple {
  border: 1px solid #dcdcdc;
  min-height: 46px;
}

.select2-dropdown {
  border-color: #dcdcdc;
}

.p-20 {
  padding: 20px !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.block {
  display: block !important;
}

.cal-icon {
  position: relative;
  width: 100%;
}
.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: "FontAwesome";
  font-size: 15px;
  margin: auto;
  position: absolute;
  top: 10px;
  right: 15px;
}

/****** PAGES ******/
.dash-widget-icon {
  display: inline-flex;
  font-size: 30px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  width: 50px;
  border: 3px solid;
  border-radius: 50px;
  padding: 28px;
}
.dash-widget-icon h3 {
  margin-bottom: 10px;
}

.dash-count {
  font-size: 18px;
  margin-left: auto;
}
@media (max-width: 767.98px) {
  .dash-count {
    font-size: 16px;
  }
}

.dash-widget-header {
  align-items: center;
  display: flex;
  margin-bottom: 15px;
}

.card-chart .card-body {
  padding: 8px;
}
@media (max-width: 575.98px) {
  .card-chart .card-body {
    padding: 5px;
  }
}

#morrisArea > svg,
#morrisArea #morrisLine > svg {
  width: 100%;
}

.activity-feed {
  list-style: none;
  margin-bottom: 0;
  margin-left: 5px;
  padding: 0;
}
.activity-feed .feed-item {
  border-left: 2px solid #e4e8eb;
  padding-bottom: 19px;
  padding-left: 20px;
  position: relative;
}
.activity-feed .feed-item:last-child {
  border-color: transparent;
  padding-bottom: 0;
}
.activity-feed .feed-item:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: #177dff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: -7px;
}
.activity-feed .feed-item .feed-date {
  display: block;
  position: relative;
  color: #777;
  text-transform: uppercase;
  font-size: 13px;
}
.activity-feed .feed-item .feed-text {
  color: #777;
  position: relative;
}
.activity-feed .feed-item .feed-text a {
  color: #333333;
  font-weight: 600;
}

.section-header {
  margin-bottom: 1.875rem;
}
.section-header .section-title {
  color: #333333;
}

.comp-header {
  margin-bottom: 1.875rem;
}
.comp-header .comp-title {
  color: #272b41;
}

.line {
  background-color: #20c0f3;
  height: 2px;
  width: 60px;
  margin: 0;
}

.comp-buttons .btn {
  margin-bottom: 5px;
}

.pagination-box .pagination {
  margin-top: 0;
}

.comp-dropdowns .btn-group {
  margin-bottom: 5px;
}

.progress-example .progress {
  margin-bottom: 1.5rem;
}

.progress-xs {
  height: 4px;
}

.progress-sm {
  height: 15px;
}

.progress.progress-sm {
  height: 6px;
}
.progress.progress-md {
  height: 8px;
}
.progress.progress-lg {
  height: 18px;
}

.row.row-sm {
  margin-left: -3px;
  margin-right: -3px;
}
.row.row-sm > div {
  padding-left: 3px;
  padding-right: 3px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #00d0f1;
}

@media (max-width: 575.98px) {
  .pagination-lg .page-link {
    font-size: 1.2rem;
    padding: 0.5rem 0.625rem;
  }
}

.notifications {
  padding: 0;
}
.notifications .notification-time {
  font-size: 12px;
  line-height: 1.35;
  color: #bdbdbd;
}
.notifications .notify-block {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}
.notifications .notify-block:last-child {
  border-bottom: none;
}
.notifications .notify-block a {
  display: block;
  padding: 10px 15px;
  border-radius: 2px;
}
.notifications .notify-block a:hover {
  background-color: #fafafa;
}
.notifications .notify-block > .avatar {
  margin-right: 10px;
}
.notifications .media-list .media-left {
  padding-right: 8px;
}
.notifications .noti-content {
  height: 290px;
  width: 350px;
  overflow-y: auto;
  position: relative;
}
@media (max-width: 479px) {
  .notifications .noti-content {
    width: 300px;
  }
}
.notifications ul.notification-list > li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
  border-bottom: none;
}
.notifications ul.notification-list > li a {
  display: block;
  border-radius: 2px;
  padding: 10px 15px;
}
.notifications ul.notification-list > li a:hover {
  background-color: #fafafa;
}
.notifications ul.notification-list > li .list-item {
  border: 0;
  position: relative;
  padding: 0;
}

.topnav-dropdown-header {
  border-bottom: 1px solid #eeeeee;
  text-align: center;
}
.topnav-dropdown-header .notification-title {
  color: #333333;
  display: block;
  float: left;
  font-size: 14px;
}
.topnav-dropdown-header .clear-noti {
  color: #f83f37;
  float: right;
  font-size: 12px;
  text-transform: uppercase;
}

.topnav-dropdown-header,
.topnav-dropdown-footer {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.topnav-dropdown-footer {
  border-top: 1px solid #eeeeee;
}
.topnav-dropdown-footer a {
  display: block;
  text-align: center;
  color: #333333;
}

.noti-details {
  color: #989c9e;
  margin-bottom: 0;
}

.noti-title {
  color: #333333;
}

.notification-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.noti-time {
  margin: 0;
}

.login-body {
  display: table;
  height: 100vh;
  min-height: 100vh;
}

.login-wrapper {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.login-wrapper .loginbox {
  background-color: #ffffff;
  display: flex;
  max-width: 800px;
  min-height: 500px;
  width: 100%;
  margin: 1.875rem auto;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .login-wrapper .loginbox {
    max-width: 450px;
    min-height: unset;
  }
}
.login-wrapper .loginbox .login-left {
  align-items: center;
  flex-direction: column;
  width: 400px;
  display: flex;
  background: linear-gradient(180deg, #1b5a90, #00d0f1);
  border-radius: 6px 0 0 6px;
  padding: 80px;
}
@media (max-width: 991.98px) {
  .login-wrapper .loginbox .login-left {
    width: 50%;
    padding: 80px 50px;
  }
}
@media (max-width: 767.98px) {
  .login-wrapper .loginbox .login-left {
    display: none;
  }
}
.login-wrapper .loginbox .login-right {
  padding: 40px;
  width: 400px;
}
@media (max-width: 991.98px) {
  .login-wrapper .loginbox .login-right {
    width: 50%;
    padding: 50px;
  }
}
@media (max-width: 767.98px) {
  .login-wrapper .loginbox .login-right {
    float: none;
    width: 100%;
    padding: 1.875rem;
  }
}
.login-wrapper .loginbox .login-right .login-right-wrap {
  max-width: 100%;
  flex: 0 0 100%;
}
.login-wrapper .loginbox .login-right h1 {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .login-wrapper .loginbox .login-right h1 {
    font-size: 22px;
  }
}
.login-wrapper .loginbox .login-right .forgotpass a {
  color: #a0a0a0;
}
.login-wrapper .loginbox .login-right .forgotpass a:hover {
  color: #333333;
  text-decoration: underline;
}
.login-wrapper .loginbox .login-right .dont-have {
  color: #a0a0a0;
  margin-top: 1.875rem;
}
.login-wrapper .loginbox .login-right .dont-have a {
  color: #333333;
}
.login-wrapper .loginbox .login-right .dont-have a:hover {
  text-decoration: underline;
}

.account-subtitle {
  color: #4c4c4c;
  font-size: 17px;
  margin-bottom: 1.875rem;
  text-align: center;
}

.social-login {
  text-align: center;
}
.social-login > span {
  color: #a0a0a0;
  margin-right: 8px;
}
.social-login > a {
  background-color: #cccccc;
  color: #ffffff;
  display: inline-block;
  font-size: 18px;
  height: 32px;
  line-height: 32px;
  margin-right: 6px;
  text-align: center;
  width: 32px;
  border-radius: 4px;
}
.social-login > a:last-child {
  margin-right: 0;
}
.social-login > a.facebook {
  background-color: #4b75bd;
}
.social-login > a.google {
  background-color: #fe5240;
}

.login-or {
  color: #a0a0a0;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.or-line {
  background-color: #e5e5e5;
  height: 1px;
  display: block;
  margin: 0 auto;
}

.span-or {
  background-color: #ffffff;
  display: block;
  margin-left: -20px;
  text-align: center;
  text-transform: uppercase;
  width: 42px;
  position: absolute;
  top: 0;
  left: 50%;
}

.lock-user {
  margin-bottom: 20px;
  text-align: center;
}
.lock-user img {
  margin-bottom: 15px;
  width: 100px;
}

.add-btn {
  border: 1px solid transparent;
  color: #666;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  border-radius: 20px;
}
@media (max-width: 479px) {
  .add-btn {
    font-size: 14px;
    padding: 6px 7px;
  }
}
.add-btn:focus,
.add-btn:active,
.add-btn:hover {
  background-color: #ffffff;
  border-color: #dddddd;
  color: #666;
}
.add-btn span {
  align-items: center;
  background-color: #ffc107;
  color: #ffffff;
  display: inline-flex;
  font-size: 14px;
  height: 22px;
  justify-content: center;
  margin-right: 3px;
  width: 22px;
  border-radius: 50%;
}

.invoice-details {
  float: right;
  text-align: right;
}
@media (max-width: 575.98px) {
  .invoice-details {
    float: left;
    text-align: left;
  }
}
.invoice-details h4 {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 1.875rem;
  text-transform: uppercase;
}

.inv-badge {
  color: #ffffff;
  font-size: 11px;
  min-width: 80px;
  line-height: normal;
}

.inv-logo {
  max-height: 80px;
  height: auto;
  max-height: 100px;
  width: auto;
}

.invoice-container {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  max-width: 900px;
  margin: 0 auto 1.875rem;
  padding: 1.5rem;
}
@media (max-width: 767.98px) {
  .invoice-container {
    padding: 20px;
  }
}

.invoice-payment-details > li span {
  float: right;
  text-align: right;
}
@media (max-width: 575.98px) {
  .invoice-payment-details > li span {
    float: left;
    text-align: left;
  }
}

#calendar-events {
  background-color: #fcfcfc;
}

.calendar-events {
  border: 1px solid transparent;
  cursor: move;
  padding: 10px 15px;
}
.calendar-events:hover {
  border-color: #e9e9e9;
  background-color: #ffffff;
}
.calendar-events i {
  margin-right: 8px;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5rem;
}
@media (max-width: 575.98px) {
  .fc-toolbar .fc-center {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .fc-toolbar .fc-center h2 {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .fc-toolbar .fc-left {
    float: none;
    width: 200px;
    margin: 0 auto;
  }
}
@media (max-width: 575.98px) {
  .fc-toolbar .fc-right {
    display: inline-block;
    float: none;
    width: 200px;
    clear: both;
    margin: 10px auto 0;
  }
}
.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:hover,
.fc-toolbar button:focus {
  z-index: 0;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-day-grid-event .fc-time {
  font-family: "Roboto", sans-serif;
}

.fc-day {
  background: #ffffff;
}

.fc th.fc-widget-header {
  background: #eeeeee;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 10px 0;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f3f3f3;
}
.fc-unthemed .fc-today {
  background: #ffffff;
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
  padding: 2px 5px;
}
.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  height: auto !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #f3f3f3;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #20c0f3 !important;
  color: #ffffff !important;
  text-shadow: none !important;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-event {
  border: none;
  color: #ffffff !important;
  cursor: move;
  font-size: 13px;
  text-align: center;
  border-radius: 2px;
  margin: 1px 7px;
  padding: 5px 5px;
}

.event-form .input-group .form-control {
  height: 40px;
}

.dropdown-action {
  margin-bottom: 5px;
}
.dropdown-action .dropdown-toggle:after {
  display: none;
}

.table-inbox {
  font-size: 15px;
  margin-bottom: 0;
}
.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
  cursor: pointer;
}
.table-inbox .starred.fa-star,
.table-inbox .fa-star {
  color: #ffd200;
}
.table-inbox tr {
  cursor: pointer;
}

.mail-list {
  list-style: none;
  padding: 0;
}
.mail-list > li > a {
  color: #333333;
  display: block;
  padding: 10px;
}
.mail-list > li.active > a {
  color: #00d0f1;
  font-weight: 700;
}

.unread .name,
.unread .subject,
.unread .mail-date {
  color: #000000;
  font-weight: 600;
}

.table.table-inbox > tbody > tr > th,
.table.table-inbox > tbody > tr > td,
.table.table-inbox > thead > tr > th,
.table.table-inbox > thead > tr > td,
.table.table-inbox > tfoot > tr > th,
.table.table-inbox > tfoot > tr > td {
  border-bottom: 1px solid #f2f2f2;
  border-top: 0;
}
.table.table-inbox thead {
  background-color: #ffffff;
}

.note-editor.note-frame {
  border: 1px solid #dddddd;
  box-shadow: inherit;
}
.note-editor.note-frame .note-statusbar {
  background-color: #ffffff;
}
.note-editor.note-frame.fullscreen {
  top: 60px;
}
.note-editor.note-frame .btn-light {
  background-color: #f9f9f9;
  box-shadow: unset;
  color: #333333;
}

.mail-title {
  font-weight: 700;
  text-transform: uppercase;
}

.form-control.search-message {
  border-color: #cccccc;
  height: 38px;
  width: 180px;
  border-radius: 4px;
}

table.table-inbox tbody tr.checked {
  background-color: #ffffcc;
}

.mail-label {
  font-size: 16px !important;
  margin-right: 5px;
}

.attachments {
  list-style: none;
  margin: 0;
  padding: 0;
}
.attachments li {
  border: 1px solid #eeeeee;
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 180px;
}

.attach-info {
  background-color: #f4f4f4;
  padding: 10px;
}

.attach-file {
  color: #777;
  font-size: 70px;
  min-height: 138px;
  padding: 10px;
}
.attach-file img {
  height: auto;
  max-width: 100%;
}

.mailview-header,
.mailview-footer {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.mailview-footer .btn-white {
  margin-top: 10px;
  min-width: 102px;
}

.sender-img {
  float: left;
  margin-right: 10px;
  width: 40px;
}

.sender-name {
  display: block;
}

.receiver-name {
  color: #777;
}

.right-action,
.mail-view-action,
.mail-sent-time {
  text-align: right;
}

@media (max-width: 767.98px) {
  .mail-sent-time {
    float: left;
    margin-top: 10px;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .right-action {
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  .left-action {
    text-align: center;
    margin-bottom: 15px;
  }
}

.mail-view-title {
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.inbox-menu {
  display: inline-block;
  width: 100%;
  margin: 0 0 1.875rem;
  padding: 0;
}
.inbox-menu li {
  display: inline-block;
  width: 100%;
}
.inbox-menu li + li {
  margin-top: 2px;
}
.inbox-menu li a {
  color: #333333;
  display: inline-block;
  width: 100%;
  text-transform: capitalize;
  -webkit-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  transition: 0.3s ease;
  padding: 10px 15px;
}
.inbox-menu li a i {
  font-size: 16px;
  padding-right: 10px;
  color: #878787;
}
.inbox-menu li a:hover,
.inbox-menu li a:focus {
  background: rgba(33, 33, 33, 0.05);
}
.inbox-menu li.active a {
  background: rgba(33, 33, 33, 0.05);
}

.compose-btn {
  margin-bottom: 1.875rem;
}
.compose-btn a {
  font-weight: 600;
  padding: 8px 15px;
}

.error-page {
  align-items: center;
  color: #1f1f1f;
  display: flex;
}
.error-page .main-wrapper {
  height: auto;
  width: 100%;
  min-height: unset;
}

.error-box {
  max-width: 480px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 1.875rem 0;
}
.error-box h1 {
  color: #00d0f1;
  font-size: 10em;
}
@media (max-width: 767.98px) {
  .error-box h1 {
    font-size: 6em;
  }
}
.error-box p {
  margin-bottom: 1.875rem;
}
.error-box .btn {
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  border-radius: 50px;
  padding: 10px 20px;
}
@media (max-width: 767.98px) {
  .error-box .btn {
    font-size: 15px;
    min-width: 150px;
    padding: 8px 20px;
  }
}

.profile-header {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  padding: 1.5rem;
}
.profile-header img {
  height: auto;
  max-width: 120px;
  width: 120px;
}

.profile-menu {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  padding: 0.9375rem 1.5rem;
}
.profile-menu .nav-tabs.nav-tabs-solid {
  background-color: transparent;
}

.profile-tab-cont {
  padding-top: 1.875rem;
}
@media (max-width: 575.98px) {
  .profile-tab-cont {
    padding-top: 1.25rem;
  }
}

.about-text {
  max-width: 500px;
}

.skill-tags span {
  background-color: #f4f4f5;
  color: #66676b;
  display: inline-block;
  font-size: 15px;
  line-height: 22px;
  border-radius: 4px;
  margin: 2px 0;
  padding: 5px 15px;
}

.edit-link {
  color: #66676b;
  font-size: 16px;
  margin-top: 4px;
}
@media (max-width: 767.98px) {
  .edit-link {
    font-size: 0.875rem;
    margin-top: 0;
  }
}

.cal-icon {
  position: relative;
  width: 100%;
}
.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: "FontAwesome";
  font-size: 15px;
  margin: auto;
  position: absolute;
  top: 10px;
  right: 15px;
}

.form-title {
  width: 100%;
  max-width: 100%;
  font-size: 20px;
  font-weight: 500;
  line-height: inherit;
  color: #333333;
  white-space: normal;
  position: relative;
  display: block;
  margin: auto auto 20px;
  padding: 0;
}
.form-title:before {
  content: "";
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  right: 0px;
  left: 0;
}
.form-title span {
  background-color: #ffffff;
  display: inline-block;
  z-index: 2;
  position: relative;
  padding: 0px 0.5rem 0 0;
}

@media (max-width: 575.98px) {
  .account-wrapper {
    padding: 0.9375rem;
  }
}

@media (max-width: 575.98px) {
  .profile-image {
    flex: 0 0 100%;
    margin-bottom: 20px;
    text-align: center;
  }
}

@media (max-width: 575.98px) {
  .profile-user-info {
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  .profile-btn {
    flex: 0 0 100%;
    margin-top: 20px;
  }
}
@media (max-width: 575.98px) {
  .profile-btn {
    text-align: center;
  }
}

/*# sourceMappingURL=custom.css.map */
