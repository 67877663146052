.LYKI7 {
  display: flex;
  justify-content: space-between;
}

.LYKI7 .LYKI7-right {
  display: flex;
}

.LYKI7 .LYKI7-right .LYKI7-chield {
  margin-left: 10px;
}

.MJkged {
  margin-top: 20px;
  /* background-color: red; */
}

.MJkged .Ics6Hc {
  margin-left: 0;
}

.MJkged .Ics6Hc ul {
  display: flex;
  flex-wrap: wrap;
}
.MJkged .Ics6Hc ul li {
  margin-right: 10px;
  margin-bottom: 10px;
}

.input-field {
  margin: 0 30px;
}

.input-field textarea {
  border-radius: 10px;
  padding-left: 10px;
  width: 100%;
  box-sizing: border-box;
  height: 300px;
  line-height: 1.5;
  padding-top: 10px;
  box-shadow: 0 0 5px;
  background-color: #fff;
}

@media (max-width: 600px) {
  .LYKI7 {
    flex-wrap: wrap;
  }

  .LYKI7 .LYKI7-left {
    margin-bottom: 10px;
  }
  .LYKI7 .LYKI7-left .textfield {
    width: 200px;
  }
}
