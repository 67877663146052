@import "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&amp;display=swap";
@font-face {
  font-family: material icons;
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/MaterialIcons-Regular.eot);
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(./fonts/MaterialIcons-Regular.html) format("woff2"),
    url(./fonts/MaterialIcons-Regular.woff) format("woff"),
    url(./fonts/MaterialIcons-Regular.ttf) format("truetype");
}
html {
  height: 100%;
}
body {
  background-color: #fff;
  color: #131523;
  font-family: source sans pro, sans-serif;
  font-size: 0.875rem;
  height: 100%;
  line-height: 1.5;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: source sans pro, sans-serif;
  font-weight: 600;
  margin-top: 0;
  color: #131523;
}
h1 {
  font-size: 32pt;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
a {
  text-decoration: none;
}
a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}
.form-control {
  border: 1px solid #e6e9f4;
  border-radius: 5px;
  box-shadow: none;
  color: #333;
  padding: 0.54688rem 0.875rem;
  font-size: 0.875rem;
  height: calc(1.6em + 1.21875rem);
  line-height: 1.6;
  font-weight: 400;
  color: #1e2022;
}
textarea.form-control {
  height: auto;
}
.form-control:focus {
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.125);
  outline: 0;
  border-color: #c1c9d0;
}
.form-control-sm {
  height: calc(1.5em + 1rem);
  padding: 0.4375rem 0.65625rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.form-control-lg {
  height: calc(1.5em + 1.875rem);
  padding: 0.875rem 1.09375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
}
a {
  color: #00dbfb;
}
a:hover {
  color: #00dbfb;
  text-decoration: none;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
p:last-child {
  margin-bottom: 0;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}
input,
button,
a {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
input,
input:focus,
button,
button:focus {
  outline: none;
}
input[type="file"] {
  height: auto;
  min-height: calc(1.5em + 0.75rem + 2px);
}
input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
textarea.form-control {
  resize: vertical;
}
.navbar-nav > li {
  float: left;
}
.form-group {
  margin-bottom: 1.25rem;
}
.form-group img {
  width: 100%;
}
.input-group .form-control {
  height: 40px;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
}
.material-icons {
  font-family: material icons;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}
ul li {
  list-style: none;
}
p:last-child {
  margin-bottom: 0;
}
.table {
  color: #131523;
  max-width: 100%;
  margin-bottom: 0;
  width: 100%;
}
.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
  padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}
.table.dataTable {
  border-collapse: collapse !important;
}
table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
table.table td h2.table-avatar {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}
.table > tbody > tr > td {
  padding: 15px;
}
table.table td h2 a {
  color: #131523;
}
table.table td h2 a:hover {
  color: #0ce0ff;
}
table.table td h2 span {
  display: block;
  margin-top: 3px;
}
table.table td h2.table-avatar a > img {
  border-radius: 6px;
  margin-right: 12px;
}
.table thead {
  background-color: transparent;
  border-bottom: 0;
}
.table thead tr th {
  font-weight: 500;
}
.table.table-center td,
.table.table-center th {
  vertical-align: middle;
}
.table-hover tbody tr:hover {
  background-color: #fff;
}
.table td,
.table th {
  vertical-align: middle;
  white-space: nowrap;
}
.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered td:first-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.table-bordered td:last-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.table-bordered th,
.table-bordered td {
  border-color: rgba(0, 0, 0, 0.05);
}
.tab-subtext {
  font-size: 14px;
}
.dataTables_wrapper .pagination li + li {
  padding-left: 5px;
}
.dataTables_wrapper .page-item.active .page-link {
  background: #f4f6ff;
  border: 1px solid #e6e9f4;
  color: #1b5a90;
}
.dataTables_wrapper .previous .page-link,
.dataTables_wrapper .next .page-link {
  width: auto;
  height: auto;
  box-shadow: none;
  color: #1b5a90;
  font-size: 14px;
  border: 0;
}
.dataTables_wrapper .page-link {
  border: none;
  background: #fff;
  border: 1px solid #f8f8f8;
  border-radius: 4px;
  box-shadow: 0 4px 7px rgb(185 185 185/25%);
  width: 32px;
  height: 32px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
#tablepagination .dataTables_paginate {
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
}
.table.dataTable {
  margin: 0 !important;
}
.card-header .form-custom label {
  position: relative;
}
.card-header .form-custom label::before {
  position: absolute;
  font-family: "font awesome 5 free";
  font-weight: 900;
  content: "\f002";
  left: 15px;
  top: 7px;
  color: #7e84a3;
}
.card-table .card-body {
  padding: 0;
}
.card-table .card-body .table > thead > tr > th {
  border-top: 0;
}
.card-table .card-body .table tr td:first-child,
.card-table .card-body .table tr th:first-child {
  padding-left: 1.5rem;
}
.card-table .card-body .table tr td:last-child,
.card-table .card-body .table tr th:last-child {
  padding-right: 1.5rem;
}
.card-table .table td,
.card-table .table th {
  border-top: 1px solid #e2e5e8;
  padding: 1rem 0.75rem;
  white-space: nowrap;
}
.star-red {
  color: red;
}
label {
  display: inline-block;
  margin-bottom: 10px;
}
.settings-form {
  margin-top: 20px;
}
.nav-tabs.menu-tabs .nav-item.active .nav-link {
  color: #fff;
  background: #00dbfb;
}
.settings-menu-links .nav-tabs.menu-tabs {
  border: none;
  margin-bottom: 20px;
}
.settings-label {
  margin-bottom: 10px;
}
.settings-btn {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-weight: 400;
  padding: 15px;
  position: relative;
  width: 100%;
  height: 46px;
}
.settings-size {
  font-size: 14px;
  color: #131523;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 0;
}
.upload-images {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  display: flex;
  margin-top: 30px;
  width: 196px;
  height: 78px;
  justify-content: space-around;
  align-items: center;
  position: relative;
}
.settings-btn .hide-input {
  position: relative;
  z-index: 1;
  cursor: pointer;
  min-height: auto;
  padding-left: 4px;
  padding-top: 0;
  line-height: 10px;
  width: 100%;
  opacity: 0;
}
.settings-btn .upload {
  position: absolute;
  z-index: 11;
  background: #00dbfb;
  border-radius: 5px;
  right: 2px;
  top: 1.5px;
  width: 41px;
  height: 41px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.settings-size span {
  font-weight: 700;
}
.upload-images img {
  max-height: 45px;
  width: auto;
}
.upload-images .btn-icon {
  color: #00dbfb;
  font-size: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  padding: 0;
  width: 20px;
  height: 20px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  right: 0;
  top: 0;
  justify-content: center;
  -webkit-justify-content: center;
}
.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.checktoggle {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 48px;
}
.checktoggle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  width: 16px;
  height: 16px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  transition: left 300ms ease, transform 300ms ease;
}
.upload-size {
  width: 82px;
  height: 82px;
}
.settings-btns .btn-orange:hover {
  background: #00dbfb;
  border: 1px solid #00dbfb;
  color: #fff;
}
.settings-btns .btn-orange {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 26px;
  margin-right: 15px;
  background: #00dbfb;
  border: 1px solid #00dbfb;
  color: #fff;
  border-radius: 4px;
}
.settings-btns .btn-grey {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 26px;
  background: #fff;
  color: #242424;
  border: 1px solid #242424;
  border-radius: 4px;
}
.settings-btns .btn-grey:hover {
  background: #242424;
  border: 1px solid #242424;
  color: #fff;
}
.card .card-header .card-title {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 20px;
  color: #000;
  font-family: source sans pro, sans-serif;
}
.check:checked + .checktoggle {
  background-color: #00dbfb;
  border: 1px solid #00dbfb;
}
.check:checked + .checktoggle:after {
  background-color: #fff;
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
}
.nav-tabs.menu-tabs .nav-link {
  position: relative;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  background: 0 0;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: titillium web, sans-serif;
  color: #000;
  background: #00dbfb14;
}
.nav-tabs.menu-tabs .nav-item {
  padding-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
.nav-tabs.menu-tabs .nav-link:hover {
  color: #fff;
  background: #00dbfb;
}
.settings-form .social-icon {
  width: 50px;
  height: 42px;
  margin-right: 6px;
  color: #fff;
  background: #0ce0ff;
  border: 1px solid #0ce0ff;
  border-radius: 5px;
  font-size: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  padding: 4px;
}
.settings-form .links-cont .social-icon::after {
  content: "";
  border-top: 0.2em solid;
  border-right: 0.2em solid transparent;
  border-bottom: 0;
  border-left: 0.2em solid transparent;
  margin-left: 4px;
}
.settings-form .trash:hover {
  color: #fff;
  opacity: 0.8;
}
.settings-form .trash {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 42px;
  width: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background-color: #0ce0ff;
  color: #fff;
  position: relative;
  right: 16px;
  font-size: 20px;
}
.add-links {
  background-color: #17d053;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 26px;
  border-radius: 4px;
}
.add-links:hover {
  color: #fff;
  opacity: 0.8;
}
.settings-form .bootstrap-tagsinput .tag {
  margin-right: 5px;
  margin-bottom: 0;
  color: #000;
  background: #e4e4e4 !important;
  border-radius: 0;
  font-size: 15px;
  font-weight: 500;
  padding: 12px;
  font-family: titillium web, sans-serif;
}
.settings-form .bootstrap-tagsinput input {
  line-height: 36px;
}
.settings-form .bootstrap-tagsinput {
  display: block;
  border-radius: 5px;
  color: #333;
  background: #fff;
  border: 1px solid #d7d7d7;
  box-shadow: unset;
  min-height: 46px;
  padding-top: 4px;
  padding-left: 5px;
  padding-right: 10px;
}
.settings-form .custom_radio {
  color: #000;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 0.9375rem;
  padding-left: 30px;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.settings-form .custom_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #00dbfb;
  border-radius: 50%;
}
.settings-form .custom_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #00dbfb;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.darkmode .card .card-header .card-title {
  color: #97a2d2;
}
.darkmode .nav-tabs.menu-tabs .nav-link {
  color: #97a2d2;
}
.darkmode .settings-form .custom_radio {
  color: #97a2d2;
}
.darkmode .page-header .breadcrumb a {
  color: #97a2d2;
}
.darkmode .breadcrumb-item.active {
  color: #97a2d2;
}
.darkmode .text-muted {
  color: #97a2d2 !important;
}
.darkmode .d-block {
  color: #97a2d2;
}
.darkmode .sorting_1 {
  color: #97a2d2;
}
.darkmode td {
  color: #97a2d2;
}
.darkmode th {
  color: #97a2d2;
}
.block {
  display: block !important;
}
.bullets li {
  list-style: inherit;
}
#toggle_btn i {
  font-size: 22px;
}
.bg-1 {
  background-color: #0ce0ff;
}
.bg-blue {
  background-color: #1b5a90;
}
.bg-3 {
  background-color: #ffbc00;
}
.bg-4 {
  background-color: #f0142f;
}
.bg-5 {
  background-color: #ffb800;
}
.bg-6 {
  background-color: #42cdff;
}
.bg-7 {
  background-color: #52fb6a;
}
.bg-8 {
  background-color: #f555ff;
}
.table .btn {
  white-space: nowrap;
}
table .badge {
  border-radius: 5px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  min-width: 75px;
  padding: 7px 12px;
  text-align: center;
}
.feather {
  width: 18px;
  height: 18px;
}
.btn.focus,
.btn:focus {
  box-shadow: unset;
}
.btn-white {
  background-color: #fff;
  border-color: #e6e9f4;
}
.btn.btn-rounded {
  border-radius: 50px;
}
.bg-primary,
.badge-primary {
  background-color: #0ce0ff !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #621aff !important;
}
.bg-success,
.badge-success {
  background-color: #22cc62 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1eae55 !important;
}
.bg-info,
.badge-info {
  background-color: #009efb !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
  background-color: #ffbc00 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #e9ab2e !important;
}
.bg-danger,
.badge-danger {
  background-color: #f0142f !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #e63333 !important;
}
.bg-white {
  background-color: #fff;
}
.bg-purple,
.badge-purple {
  background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
  color: #0ce0ff !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
  color: #17d053 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
  color: #f0142f !important;
}
.text-yellow {
  color: #ffbc00 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
  color: #009efb !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
  color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
  color: #7460ee !important;
}
.text-muted {
  color: #7e84a3 !important;
}
.btn-primary {
  background-color: #0ce0ff;
  border: 1px solid #0ce0ff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-color: #00dbfb;
  border: 1px solid #00dbfb;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  background-color: #00dbfb;
  border: 1px solid #00dbfb;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
  background-color: #00dbfb;
  border-color: #00dbfb;
  color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #0ce0ff;
  border-color: #0ce0ff;
  color: #fff;
}
.btn-secondary {
  background-color: #1b5a90;
  border: 1px solid #1b5a90;
}
.btn-secondary:hover {
  background-color: #08508e;
  border: 1px solid #08508e;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-success {
  background-color: #22cc62;
  border: 1px solid #22cc62;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
  background-color: #1eae55;
  border: 1px solid #1eae55;
  color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
  background-color: #1eae55;
  border: 1px solid #1eae55;
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
  background-color: #1eae55;
  border-color: #1eae55;
  color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-success.disabled,
.btn-success:disabled {
  background-color: #22cc62;
  border-color: #22cc62;
  color: #fff;
}
.btn-info {
  background-color: #009efb;
  border: 1px solid #009efb;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-info.disabled,
.btn-info:disabled {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff;
}
.btn-warning {
  background-color: #ffbc34;
  border: 1px solid #ffbc34;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffbc34;
  border-color: #ffbc34;
  color: #fff;
}
.badge-secondary {
  background-color: #621aff !important;
}
.btn-danger {
  background-color: #ef3737;
  border: 1px solid #ef3737;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
  background-color: #ec1313;
  border: 1px solid #ec1313;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  background-color: #ec1313;
  border: 1px solid #ec1313;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
  background-color: #ec1313;
  border-color: #ec1313;
  color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #f62d51;
  border-color: #f62d51;
  color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled),
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-outline-primary {
  color: #0ce0ff;
  border-color: #0ce0ff;
}
.btn-outline-primary:hover {
  background-color: #0ce0ff;
  border-color: #0ce0ff;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #0ce0ff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #0ce0ff;
  border-color: #0ce0ff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-outline-secondary {
  color: #1b5a90;
  border-color: #1b5a90;
}
.btn-outline-secondary:hover {
  background-color: #1b5a90;
  border-color: #1b5a90;
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  background-color: #1b5a90;
  border-color: #1b5a90;
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #1b5a90;
}
.btn-outline-success {
  color: #22cc62;
  border-color: #22cc62;
}
.btn-outline-success:hover {
  background-color: #22cc62;
  border-color: #22cc62;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: none;
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #22cc62;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  background-color: #22cc62;
  border-color: #22cc62;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-outline-info {
  color: #00bcd4;
  border-color: #00bcd4;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: none;
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  color: #00bcd4;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-outline-warning {
  color: #ffbc34;
  border-color: #ffbc34;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: none;
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  background-color: transparent;
  color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-outline-danger {
  color: #ef3737;
  border-color: #ef3737;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ef3737;
  border-color: #ef3737;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: none;
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  background-color: transparent;
  color: #ef3737;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  background-color: #ef3737;
  border-color: #ef3737;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-outline-light {
  color: #ababab;
  border-color: #e6e6e6;
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #ababab;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #0ce0ff;
  border-color: #0ce0ff;
}
.pagination > li > a,
.pagination > li > span {
  color: #131523;
}
.page-link:hover {
  color: #0ce0ff;
}
.page-link:focus {
  box-shadow: unset;
}
.page-item.active .page-link {
  background-color: #0ce0ff;
  border-color: #0ce0ff;
}
.dropdown-menu {
  border: 1px solid #eff2f7;
  border-radius: 0.8rem;
  transform-origin: left top 0;
  background-color: #fff;
  box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08);
}
.navbar-nav .open .dropdown-menu {
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.dropdown-menu {
  font-size: 14px;
}
.card {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #eef1fe;
  border-radius: 5px;
  -webkit-box-shadow: 0 7px 10px #eef1fe;
  box-shadow: 0 7px 10px #eef1fe;
  margin-bottom: 30px;
}
.card-body {
  position: relative;
  padding: 15px;
}
.card .card-header {
  border-color: #f0f1f5;
  background-color: #fff;
  padding: 15px;
  border-bottom: 1px solid #e6e9f4;
}
.card-header:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.card-footer:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.card-footer {
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  padding: 1rem;
}
.table .thead-light th {
  color: #131523;
  background-color: #f4f6ff;
  border-color: #eff2f7;
  border-bottom: 0;
}
.card .card-header .card-title {
  margin-bottom: 0;
}
.modal-footer.text-left {
  text-align: left;
}
.modal-footer.text-center {
  text-align: center;
}
.btn-light {
  border-color: #e6e6e6;
  color: #a6a6a6;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #0ce0ff;
  text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: #0ce0ff;
}
.bg-info-light {
  background-color: rgba(2, 182, 179, 0.12) !important;
  color: #1db9aa !important;
}
.bg-primary-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}
.bg-danger-light {
  background-color: rgba(242, 17, 54, 0.12) !important;
  color: #e63c3c !important;
}
.bg-warning-light {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}
.bg-success-light {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}
.bg-purple-light {
  background-color: rgba(197, 128, 255, 0.12) !important;
  color: #c580ff !important;
}
.bg-default-light {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}
.modal {
  -webkit-overflow-scrolling: touch;
}
.modal-content {
  border-radius: 15px;
}
.modal-footer.text-center {
  justify-content: center;
}
.modal-footer.text-left {
  justify-content: flex-start;
}
.modal-dialog.modal-md {
  max-width: 600px;
}
.custom-modal .modal-content {
  border: 0;
  border-radius: 10px;
}
.custom-modal .modal-footer {
  border: 0;
}
.custom-modal .modal-header {
  border: 0;
  justify-content: center;
  padding: 30px 30px 0;
}
.custom-modal .modal-footer {
  border: 0;
  justify-content: center;
  padding: 0 30px 30px;
}
.custom-modal .modal-body {
  padding: 30px;
}
.custom-modal .close {
  background-color: #a0a0a0;
  border-radius: 50%;
  color: #fff;
  font-size: 13px;
  height: 20px;
  line-height: 20px;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  z-index: 99;
}
.custom-modal .modal-title {
  font-size: 22px;
}
.modal-backdrop.show {
  opacity: 0.4;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
}
.modal .card {
  box-shadow: unset;
}
.select2-container .select2-selection--single {
  border: 1px solid #e6e9f4;
  height: 40px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 38px;
  right: 7px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #131523;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  height: 0;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.select2-container--default.form-control-sm
  .select2-selection--single
  .select2-selection__arrow {
  top: 7px;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #131523;
  border-width: 2px 0 0 2px;
  padding: 3px;
  margin-top: -2px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 15px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #131523;
  font-size: 15px;
  font-weight: 400;
  line-height: 38px;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #eef1fe;
  color: #131523;
}
.select2-container--default .select2-selection--multiple {
  border: 1px solid #ddd;
  min-height: 40px;
}
.select2-dropdown {
  border: 1px solid #e6e9f4;
  border-top: 0;
}
.nav-tabs {
  border-bottom: 0;
}
.card-header-tabs {
  border-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}
.card-header-pills {
  margin-right: 0;
  margin-left: 0;
}
.nav-tabs > li > a {
  margin-right: 0;
  color: #888;
  border-radius: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: transparent;
  color: #333;
}
.nav-tabs.nav-tabs-solid > li > a {
  color: #333;
}
.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
  background-color: #0ce0ff;
  border-color: #0ce0ff;
  color: #fff;
}
.tab-content {
  padding-top: 20px;
}
.nav-tabs .nav-link {
  border-radius: 0;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
  color: #333;
}
.nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
  color: #333;
  padding: 10px 30px;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
  background-color: #0ce0ff;
  border-color: #0ce0ff;
  color: #fff;
  padding: 10px 30px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
  border-radius: 50px;
}
.nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
  border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0;
}
.nav-tabs.nav-tabs-top > li {
  margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li + li > a {
  margin-left: 1px;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
  border-top-color: #0ce0ff;
}
.nav-tabs.nav-tabs-bottom > li {
  margin-bottom: -1px;
}
.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: #0ce0ff;
  background-color: transparent;
  transition: none 0s ease 0s;
  -moz-transition: none 0s ease 0s;
  -o-transition: none 0s ease 0s;
  -transition: none 0s ease 0s;
  -webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid {
  background-color: #fafafa;
  border: 0;
}
.nav-tabs.nav-tabs-solid > li {
  margin-bottom: 0;
}
.nav-tabs.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
  background-color: #f5f5f5;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
  background-color: #f5f5f5;
  border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0;
}
.section-header {
  margin-bottom: 1.875rem;
}
.section-header .section-title {
  color: #333;
}
.line {
  background-color: #0ce0ff;
  height: 2px;
  margin: 0;
  width: 60px;
}
.comp-buttons .btn {
  margin-bottom: 5px;
}
.pagination-box .pagination {
  margin-top: 0;
}
.comp-dropdowns .btn-group {
  margin-bottom: 5px;
}
.progress-example .progress {
  margin-bottom: 1.5rem;
}
.progress-xs {
  height: 4px;
}
.progress-sm {
  height: 15px;
}
.progress.progress-sm {
  height: 6px;
}
.progress.progress-md {
  height: 8px;
}
.progress.progress-lg {
  height: 18px;
}
.row.row-sm {
  margin-left: -3px;
  margin-right: -3px;
}
.row.row-sm > div {
  padding-left: 3px;
  padding-right: 3px;
}
.avatar {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 42px;
}
.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.avatar-title {
  width: 100%;
  height: 100%;
  background-color: #0ce0ff;
  color: #fff;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}
.ava-status.avatar-away::before,
.ava-status.avatar-offline::before,
.ava-status.avatar-online::before {
  right: 1px;
}
.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
  position: absolute;
  right: 16px;
  bottom: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  content: "";
  z-index: 2;
}
.avatar-online::before {
  background-color: #17d053;
}
.avatar-offline:before {
  background-color: #f0142f;
}
.avatar-away:before {
  background-color: #ffbc34;
}
.avatar .border {
  border-width: 3px !important;
}
.avatar .rounded {
  border-radius: 6px !important;
}
.avatar .avatar-title {
  font-size: 18px;
}
.avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}
.avatar-xs .border {
  border-width: 2px !important;
}
.avatar-xs .rounded {
  border-radius: 4px !important;
}
.avatar-xs .avatar-title {
  font-size: 12px;
}
.avatar-xs.avatar-away:before,
.avatar-xs.avatar-offline:before,
.avatar-xs.avatar-online:before {
  border-width: 1px;
}
.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}
.avatar-sm .border {
  border-width: 3px !important;
}
.avatar-sm .rounded {
  border-radius: 4px !important;
}
.avatar-sm .avatar-title {
  font-size: 15px;
}
.avatar-sm.avatar-away:before,
.avatar-sm.avatar-offline:before,
.avatar-sm.avatar-online:before {
  border-width: 2px;
}
.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}
.avatar-lg .border {
  border-width: 3px !important;
}
.avatar-lg .rounded {
  border-radius: 8px !important;
}
.avatar-lg .avatar-title {
  font-size: 24px;
}
.avatar-lg.avatar-away:before,
.avatar-lg.avatar-offline:before,
.avatar-lg.avatar-online:before {
  border-width: 3px;
}
.avatar-xl {
  width: 5rem;
  height: 5rem;
}
.avatar-xl .border {
  border-width: 4px !important;
}
.avatar-xl .rounded {
  border-radius: 8px !important;
}
.avatar-xl .avatar-title {
  font-size: 28px;
}
.avatar-xl.avatar-away:before,
.avatar-xl.avatar-offline:before,
.avatar-xl.avatar-online:before {
  border-width: 4px;
}
.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}
.avatar-xxl .border {
  border-width: 6px !important;
}
.avatar-xxl .rounded {
  border-radius: 8px !important;
}
.avatar-xxl .avatar-title {
  font-size: 30px;
}
.avatar-xxl.avatar-away:before,
.avatar-xxl.avatar-offline:before,
.avatar-xxl.avatar-online:before {
  border-width: 4px;
}
.avatar-group {
  display: inline-flex;
}
.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.40625rem;
}
.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}
.avatar-group .avatar:hover {
  z-index: 1;
}
.header {
  background: #fff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1002;
  height: 60px;
  border-bottom: 1px solid #f5f6fa;
}
.header .header-left {
  float: left;
  height: 60px;
  padding: 0 15px;
  position: relative;
  text-align: left;
  width: 200px;
  border-bottom: 1px solid #f5f6fa;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
}
.header .header-left .logo {
  display: inline-block;
  line-height: 60px;
}
.header .header-left .logo img {
  max-height: 45px;
  width: auto;
}
.header-left .logo.logo-small {
  display: none;
}
.header .dropdown-menu > li > a {
  position: relative;
}
.header .dropdown-toggle:after {
  display: none;
}
.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #333;
  border-right: 2px solid #333;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
  -webkit-transform: rotate(-135deg);
  -transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
  display: flex !important;
  align-items: center;
  padding-right: 20px;
}
.user-menu.nav > li > a {
  color: #333;
  font-size: 14px;
  padding: 0 15px;
}
.user-img {
  display: inline-block;
  margin-right: 3px;
  position: relative;
}
.user-img img {
  width: 32px;
  border: 2px solid #eef1fe;
  border-radius: 50%;
}
.dropdown-item img {
  margin-right: 5px;
}
.user-menu.nav > li > a.mobile_btn {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.user-menu .dropdown-menu {
  min-width: 420px;
  padding: 0;
  transform: translate(0px, 59px) !important;
}
.user-menu .dropdown-menu .dropdown-item {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  padding: 10px 15px;
}
.user-menu .dropdown-menu .dropdown-item:hover {
  color: #0ce0ff;
  background-color: transparent;
}
.user-menu.nav > li.main-drop > a {
  padding-right: 0;
}
.user-menu.nav-tabs .nav-link:focus,
.user-menu.nav-tabs .nav-link:hover {
  background-color: transparent;
}
.user-header {
  background-color: #f5f6fa;
  border-radius: 5px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  padding: 10px;
}
.user-header:before,
.notifications:before {
  border: 7px solid #fff;
  border-color: transparent transparent #fff #fff;
  box-shadow: -2px 2px 2px -1px rgb(0 0 0/10%);
  content: "";
  right: 0;
  position: absolute;
  top: 2px;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.user-header:before {
  border: 7px solid #f5f6fa;
  border-color: transparent transparent #f5f6fa #f5f6fa;
}
.user-header .avatar {
  width: auto;
}
.user-header .avatar img {
  width: 28px;
  height: 28px;
}
.user-header .user-text {
  margin-left: 10px;
}
.user-header .user-text h6 {
  margin-bottom: 2px;
}
.user-menu.nav > li > a.dark-mode-toggle {
  width: 56px;
  height: 28px;
  background-color: #fff;
  color: #fff;
  font-size: 16px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  border: 1px solid rgba(66, 139, 193, 0.22);
  border-radius: 3px;
  margin: 15px 15px 15px 0;
  padding: 1px;
}
.user-menu.nav > li > a.dark-mode-toggle i {
  font-size: 15px;
  color: #fff;
  background: #fff;
  border-radius: 2px;
  line-height: 24px;
  width: 26px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}
.user-menu.nav > li > a.dark-mode-toggle i.light-mode.active,
.user-menu.nav > li > a.dark-mode-toggle i.dark-mode.active {
  background: #1b5a90;
  color: #fff;
}
.user-menu.nav > li > a.dark-mode-toggle i.dark-mode,
.user-menu.nav > li > a.dark-mode-toggle i.light-mode {
  color: #1b5a90;
}
.header .dropdown-menu > li > a:focus,
.header .dropdown-menu > li > a:hover {
  background-color: #0ce0ff;
  color: #fff;
}
.header .dropdown-menu > li > a:focus i,
.header .dropdown-menu > li > a:hover i {
  color: #fff;
}
.header .dropdown-menu > li > a {
  padding: 10px 18px;
}
.header .dropdown-menu > li > a i {
  color: #0ce0ff;
  margin-right: 10px;
  text-align: center;
  width: 18px;
}
.header .user-menu .dropdown-menu > li > a i {
  color: #0ce0ff;
  font-size: 16px;
  margin-right: 10px;
  min-width: 18px;
  text-align: center;
}
.header .user-menu .dropdown-menu > li > a:focus i,
.header .user-menu .dropdown-menu > li > a:hover i {
  color: #fff;
}
.mobile_btn {
  display: none;
  float: left;
}
.slide-nav .sidebar {
  margin-left: 0;
}
.menu-title {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 0 25px;
}
.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 1000;
}
.sidebar-overlay.opened {
  display: block;
}
html.menu-opened {
  overflow: hidden;
}
html.menu-opened body {
  overflow: hidden;
}
.list-links {
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  padding: 0;
}
.list-links li {
  margin: 0 15px 10px 0;
}
.list-links li a {
  background: #f5f6fa;
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 16px;
  color: #131523;
  border: 1px solid #f5f6fa;
  display: inline-block;
}
.list-links li a:hover {
  background: #e5e5e8;
}
.list-links li a span {
  font-size: 14px;
}
.list-links li.active a {
  background: #fff;
  border: 1px solid #0ce0ff;
  color: #1b5a90;
}
.bookingrange.btn-white {
  padding: 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  line-height: 44px;
  color: #131523;
}
.bookingrange .cal-ico {
  padding: 0 10px;
}
.ico i {
  padding: 15px 8px;
  color: #131523;
}
.ico i:first-child {
  border-left: 1px solid #e6e9f4;
  border-right: 1px solid #e6e9f4;
}
.daterangepicker .ranges li {
  margin: 10px;
  border: 1px solid #eef1fe;
  border-radius: 6px;
}
.daterangepicker .ranges li.active {
  border: 1px solid #0ce0ff;
  background: #fff;
  color: #1b5a90;
}
.daterangepicker.show-calendar .ranges {
  background: #f5f6fa;
  margin: 0;
}
.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 0;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #0ce0ff;
}
.daterangepicker td.in-range {
  background-color: #0ce0ff;
  color: #fff;
}
.daterangepicker:before {
  border-bottom: 7px solid #e6e9f4;
}
.daterangepicker.opensright:before {
  right: 9px;
  left: auto;
}
.daterangepicker:after {
  content: none;
}
.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 50px;
}
.daterangepicker .drp-selected {
  display: none;
}
.multipleSelection .selectBox,
.SortBy .selectBoxes {
  position: relative;
  background: #fff;
  width: 100%;
  padding: 6px 10px;
  font-weight: 400;
  border: 1px solid #e6e9f4;
  border-radius: 7px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.selectBoxes.order-by {
  min-width: 154px;
  background: #eef1fe;
  padding: 6px 10px;
}
.multipleSelection {
  min-width: 123px;
  position: relative;
}
.multi-filter,
.SortBy {
  position: relative;
}
.multi-filter:before {
  border: 7px solid #fff;
  border-color: transparent transparent #fff #fff;
  box-shadow: -2px 2px 2px -1px rgb(0 0 0/10%);
  content: "";
  right: 0;
  position: absolute;
  top: 2px;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.multipleSelection #checkBoxes,
.SortBy #checkBox {
  display: none;
  position: absolute;
  width: 220px;
  right: 0;
  z-index: 1;
  background: #fff;
  border: 1px solid #e6e9f4;
  box-shadow: 0 4px 4px rgba(201, 201, 201, 0.25);
  margin-top: 8px;
  padding: 15px;
  border-radius: 4px;
}
.multipleSelection #checkBoxes::before,
.SortBy #checkBox::before {
  border: 5px solid #fff;
  border-color: transparent transparent #ededed #ededed;
  box-shadow: -2px 2px 2px -1px rgb(0 0 0/10%);
  content: "";
  right: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.check-list {
  position: relative;
}
.form-custom {
  position: relative;
}
.form-custom i {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #7e84a3;
}
#checkBoxes .form-custom {
  margin-bottom: 10px;
}
.card-header .form-custom .form-control {
  padding-left: 30px;
  height: 35px;
}
#checkBoxes .form-custom .form-control {
  padding-left: 25px;
}
.multipleSelection #checkBoxes.form-custom .form-control {
  padding-left: 25px;
  background: #f5f6fa;
  border: 1px solid #e6e9f4;
  border-radius: 5px;
  height: 33px;
}
.multipleSelection #checkBoxes .form-custom i {
  position: absolute;
  top: 35%;
  left: 6px;
  color: #7e84a3;
  font-size: 12px;
}
#checkBoxes .btn,
#checkBox .btn {
  font-size: 14px;
  font-weight: 600;
  padding: 0.27rem 0.75rem;
}
.lab-title {
  font-size: 12px;
  color: #1b5a90;
}
.spl-img {
  width: 43px;
  height: 43px;
  background: #fff;
  border: 1px solid #eef1fe;
  box-shadow: 0 5px 7px #eef1fe;
  border-radius: 5px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  padding: 5px;
}
.spl-img img {
  margin: 0 !important;
}
.bg-grey {
  background: #f5f6fa !important;
}
.custom_check {
  color: #131523 !important;
  display: inline-block;
  position: relative;
  font-size: 14px !important;
  margin-bottom: 15px;
  padding-left: 25px;
  cursor: pointer;
  font-weight: 400 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 18px;
  font-family: noto sans, sans-serif;
}
.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom_check input:checked ~ .checkmark {
  background-color: #1b5a90;
  border-color: #1b5a90;
}
.custom_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #1b5a90;
  background-color: #fff;
  border-radius: 3px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.custom_check .checkmark::after {
  content: "\e92b";
  font-family: feather !important;
  font-weight: 900;
  position: absolute;
  display: none;
  left: 2px;
  top: -2px;
  color: #fff;
  font-size: 9px;
}
.custom_check input:checked ~ .checkmark:after {
  display: block;
}
.custom_radio {
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  margin: 1px 0 0;
  border: 2px solid #eef1fe;
  border-top-color: #ddd;
  border-right-color: #ddd;
  border-bottom-color: #ddd;
  border-left-color: #ddd;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.custom_radio .checkmark::after {
  position: absolute;
  left: 3px;
  top: 3px;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #1b5a90;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  -ms-transform: scale(0.1);
  -o-transform: scale(0.1);
  transform: scale(0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.custom_radio input:checked ~ .checkmark::after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.pos-top {
  position: absolute;
  top: 19px;
  right: 15px;
}
.rating-wrapper h5 {
  font-size: 14px;
  margin-bottom: 8px;
}
.ratings i {
  color: #ffbc00;
}
.ratings i.filled {
  font-weight: 600;
}
.hover-table > tbody > tr:hover {
  background: #fff;
  box-shadow: 0 0 24px #eef1fe;
}
.table thead tr th:first-child,
.table thead tr td:first-child {
  padding-left: 15px;
}
.table thead tr th:last-child,
.table thead tr td:last-child {
  padding-right: 15px;
}
.pagination-tab .page-item {
  padding: 0 5px;
}
.pagination-tab .page-link {
  border: none;
  background: #fff;
  border: 1px solid #f8f8f8;
  border-radius: 4px;
  box-shadow: 0 4px 7px rgba(185, 185, 185, 0.25);
  width: 32px;
  height: 32px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
.pagination-tab .page-item.disabled .page-link,
.pagination-tab .page-item:last-child .page-link {
  width: unset;
  height: unset;
  box-shadow: none;
  color: #1b5a90;
  font-size: 14px;
  border: 0;
}
.pagination-tab .page-item.disabled .page-link {
  padding-left: 0;
}
.pagination-tab .page-item:last-child .page-link {
  padding-right: 0;
}
.pagination-tab .page-item.disabled .page-link i,
.pagination-tab .page-item:last-child .page-link i {
  font-size: 18px;
}
.pagination-tab .page-item.active .page-link {
  background: #f4f6ff;
  border: 1px solid #e6e9f4;
  color: #1b5a90;
}
.doc-badge {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}
.doc-badge span {
  width: 25px;
  height: 15px;
  font-weight: 600;
  background: #f0142f;
  border-radius: 2px;
  color: #fff;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}
.modal.contentmodal .modal-content h3 {
  font-size: 24px;
}
.modal.contentmodal .modal-content .duration-select ul li {
  font-size: 14px;
  color: #433489;
  font-family: noto sans, sans-serif;
  font-weight: 400;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}
.modal.contentmodal .modal-content .duration-select ul.audio-set li {
  width: 50%;
  margin-bottom: 0;
}
.modal.contentmodal .modal-content .duration-select ul li i {
  font-size: 16px;
}
.modal.contentmodal .modal-content .feedback-content p {
  font-size: 14px;
  font-family: noto sans, sans-serif;
}
.modal.contentmodal .modal-content .feedback-content p span {
  font-weight: 600;
}
.modal.contentmodal .modal-content .feedback-content .review-count i {
  font-size: 20px;
  color: #ffbc00;
  margin-right: 5px;
}
.modal.contentmodal .modal-content .feedback-content .form-group textarea {
  font-size: 12px;
  resize: none;
  min-height: 90px;
  font-family: noto sans, sans-serif;
}
.modal.contentmodal .modal-content .submit-section .btn {
  padding: 7px 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  min-width: 150px;
  margin-top: 5px;
}
.modal.contentmodal .modal-content .submit-section p a {
  font-size: 14px;
  font-family: titillium web, sans-serif;
  font-weight: 600;
}
.modal.contentmodal .media {
  background-color: #fff;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 35px;
}
.modal.contentmodal .media h3 {
  font-size: 18px;
}
.modal.contentmodal .media p {
  color: #7e84a3;
}
.modal.contentmodal .media img {
  width: 74px;
  height: 74px;
  margin-right: 20px;
  border-radius: 5px;
  object-fit: cover;
}
.modal.contentmodal .member-wrapper h5 {
  font-size: 14px;
  border-bottom: 1px solid #e6e9f4;
  margin-bottom: 15px;
  padding-bottom: 10px;
}
.modal.contentmodal .mem-info h6 {
  font-size: 14px;
  margin-bottom: 7px;
}
.modal.contentmodal .mem-info p {
  font-size: 16px;
  margin-bottom: 25px;
  color: #7e84a3;
}
.modal.contentmodal .lang-wrap p {
  font-size: 16px;
  margin-bottom: 20px;
}
.modal.contentmodal .lang-wrap p span {
  color: #1b5a90;
}
.modal.contentmodal .media-body .ratings p {
  font-weight: 600;
  background: #f2b200;
  border-radius: 4px;
  padding: 5px;
  color: #fff;
}
.modal.contentmodal .media-body .ratings p i {
  color: #fff;
  font-size: 10px;
  margin-right: 3px;
}
.modal-content .close-btn {
  background: 0 0;
  border: none;
  padding: 0;
}
.modal-content .close-btn i {
  font-size: 20px;
  color: #f0142f;
}
.btn-file {
  align-items: center;
  display: inline-flex;
  font-size: 20px;
  justify-content: center;
  overflow: hidden;
  padding: 0 0.75rem;
  position: relative;
  vertical-align: middle;
}
.btn-file input {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.top-nav-search {
  float: left;
  margin-left: 20px;
}
.top-nav-search form {
  margin-top: 10px;
  position: relative;
  width: 230px;
}
.top-nav-search .form-control {
  border-color: transparent !important;
  background: 0 0;
  border-radius: 0.5rem;
  color: #7e84a3;
  height: 40px;
  padding: 10px 10px 10px 38px;
}
.top-nav-search .btn {
  background-color: transparent;
  border-color: transparent;
  color: #7e84a3;
  min-height: 40px;
  padding: 7px 15px;
  position: absolute;
  left: 0;
  top: 0;
}
.top-nav-search.active form {
  display: block;
  left: 0;
  position: absolute;
}
.search {
  position: relative;
  margin: 0 auto;
}
.search input:focus {
  outline: none;
  border-color: transparent;
  box-shadow: none;
}
.search input:focus + .results {
  display: block;
}
.search input {
  position: relative;
}
.s-icon {
  position: absolute;
  left: 15px;
  top: 10px;
  z-index: 9;
  color: #7e84a3;
}
.search .results {
  display: none;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  margin: 0;
  background: #fff;
  border: 1px solid #eef1fe;
  box-shadow: 0 4px 4px #eef1fe;
  width: 581px;
  padding: 20px 20px 0;
}
.search .results:before {
  border: 7px solid #fff;
  border-color: transparent transparent #fff #fff;
  box-shadow: -2px 2px 2px -1px rgb(0 0 0/10%);
  content: "";
  left: 35px;
  position: absolute;
  top: 2px;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.search .results li {
  display: block;
}
.search .results li {
  margin-bottom: 20px;
}
.search .results li h6 {
  font-weight: 400;
  color: #0ce0ff;
  margin-bottom: 2px;
}
.search .results li p {
  color: #7e84a3;
}
.search .results li p span {
  color: #7e84a3;
  text-decoration: underline;
}
.search .results li p span a {
  color: #7e84a3;
}
.sidebar {
  background-color: #1b5a90;
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  transition: all 0.2s ease-in-out 0s;
  width: 200px;
  z-index: 1001;
  box-shadow: 0 4px 4px #eef1fe;
  overflow-y: hidden;
}
.sidebar.opened {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sidebar-inner {
  height: 100%;
  min-height: 100%;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul {
  font-size: 15px;
  list-style-type: none;
  margin: 0;
  padding: 20px 0;
  position: relative;
}
.sidebar-menu li a {
  color: #131523;
  display: block;
  font-size: 14px;
  height: auto;
  padding: 0 20px;
}
.sidebar-menu li a:hover {
  color: #0ce0ff;
}
.sidebar-menu > ul > li > a:hover {
  color: #0ce0ff;
}
.sidebar-menu li.active > a {
  color: #0ce0ff;
  position: relative;
}
.menu-title {
  color: #ebebeb;
  display: flex;
  display: -ms-flexbox;
  font-size: 14px;
  font-weight: 600;
  opacity: 1;
  padding: 5px 15px;
  white-space: nowrap;
  position: relative;
}

.menu-title span {
  padding-right: 10px;
}
.menu-title > i {
  float: right;
  line-height: 40px;
}
.sidebar-menu li.menu-title a {
  color: #ff9b44;
  display: inline-block;
  margin-left: auto;
  padding: 0;
}
.sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px 15px;
}
.sidebar-menu ul ul a.active {
  color: #0ce0ff;
}
.mobile_btn {
  display: none;
  float: left;
}
.sidebar .sidebar-menu > ul > li > a span {
  transition: all 0.2s ease-in-out 0s;
  display: inline-block;
  margin-left: 10px;
  white-space: nowrap;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
  margin-left: auto;
}
.sidebar-menu ul ul a {
  display: block;
  font-size: 13px;
  padding: 7px 0;
  margin: 0 15px 0 40px;
  border-bottom: 1px solid #f4f6ff;
  position: relative;
  color: #7e84a3;
}
.sidebar-menu ul ul {
  display: none;
  padding: 0;
}
.sidebar-menu ul ul ul a {
  padding-left: 10px;
}
.sidebar-menu ul ul ul ul a {
  padding-left: 10px;
}
.sidebar-menu > ul > li {
  margin-bottom: 3px;
  position: relative;
}
.sidebar-menu > ul > li:last-child {
  margin-bottom: 25px;
}
.sidebar-menu .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  position: absolute;
  right: 15px;
  display: inline-block;
  font-family: "font awesome 5 free";
  font-weight: 900;
  text-rendering: auto;
  line-height: 40px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  line-height: 18px;
  top: 11px;
}
.sidebar-menu .menu-arrow:before {
  content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
  -transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sidebar-menu ul ul a .menu-arrow {
  top: 10px;
  right: 0;
}
.sidebar-menu > ul > li > a {
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  justify-content: flex-start;
  padding: 8px 15px;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu > ul > li > a svg {
  width: 18px;
}
.sidebar-menu ul li a i {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  vertical-align: middle;
  width: 20px;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li.menu-title a i {
  font-size: 16px !important;
  margin-right: 0;
  text-align: right;
  width: auto;
}
.sidebar-menu li a > .badge {
  color: #fff;
}
.event-form .input-group .form-control {
  height: 40px;
}
.submit-section {
  text-align: center;
  margin-top: 40px;
}
.submit-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
}
.dropdown-action {
  margin-bottom: 5px;
}
.dropdown-action .dropdown-toggle:after {
  display: none;
}
.action-icon {
  color: #333;
  font-size: 18px;
  display: inline-block;
}
.error-page {
  align-items: center;
  color: #1f1f1f;
  display: flex;
  display: -ms-flexbox;
}
.error-page .main-wrapper {
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  width: 100%;
  min-height: unset;
}
.error-box {
  margin: 0 auto;
  max-width: 480px;
  padding: 1.875rem 0;
  text-align: center;
  width: 100%;
}
.error-box img {
  margin-bottom: 38px;
}
.error-box h2 {
  font-weight: 700;
  color: #1b5a90;
  margin-bottom: 13px;
}
.error-box p {
  font-weight: 600;
  font-size: 18px;
  color: #7e84a3;
  margin-bottom: 30px;
}
.error-box .btn {
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 27px;
}
.main-wrapper {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
}
.page-wrapper {
  margin-left: 200px;
  padding-top: 60px;
  position: relative;
  transition: all 0.4s ease;
}
.page-wrapper > .content {
  padding: 1.875rem;
}
.page-header {
  margin-bottom: 1.875rem;
}
.content-wrap {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  min-height: calc(100vh - 60px);
}
.page-header .breadcrumb {
  background-color: transparent;
  color: #6c757d;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
}
.page-header .breadcrumb a {
  color: #333;
}
.page-title {
  color: #333;
  margin-bottom: 5px;
}
.login-bg {
  background-image: url(./img/login-bg.jpg);
  background-size: cover;
  top: 0;
  left: 0;
  position: fixed;
}
.login-banner {
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 30px 15px;
}
.login-wrap-bg {
  padding: 0;
  position: absolute;
  right: 0;
}
.login-wrapper {
  width: 100%;
  height: 100vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.login-wrapper .loginbox {
  width: 100%;
  padding: 40px;
  max-width: 530px;
}
.login-wrapper .loginbox .img-logo {
  margin-bottom: 28px;
}
.login-wrapper .loginbox h3 {
  font-size: 28px;
  margin-bottom: 15px;
}
.account-subtitle {
  color: #7e84a3;
  font-size: 16px;
  margin-bottom: 30px;
}
.login-wrapper .loginbox label {
  margin-bottom: 16px;
  width: 100%;
}
.forgot-link {
  color: #0ce0ff;
  float: right;
}
.login-wrapper .loginbox .dont-have {
  margin: 25px 0 0;
  text-align: center;
}
.login-wrapper .loginbox .dont-have a {
  color: #0ce0ff;
}
.login-wrapper .loginbox .dont-have a:hover {
  text-decoration: underline;
}
.social-login {
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
}
.social-login > a {
  background-color: #fff;
  border: 2px solid #e6e9f4;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 51px;
  margin-right: 30px;
}
.social-login > a:last-child {
  margin-right: 0;
}
.social-login > a:hover {
  opacity: 0.8;
  background: #e1f6ff;
}
.login-or {
  margin: 30px 0;
  text-align: center;
}
.login-or {
  color: #757575;
  margin: 17px 0 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}
.or-line {
  background-color: #e5e5e5;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}
.span-or {
  background-color: #fff;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  text-align: center;
  top: -3px;
  font-size: 16px;
  padding: 0 15px;
  color: #7e84a3;
}
.email-confirm {
  text-align: center;
}
.loginbox.email-confirm h3 {
  margin-bottom: 30px;
}
.loginbox.email-confirm p {
  margin-bottom: 30px;
}
.email-confirm .success-icon {
  margin-bottom: 20px;
}
.email-confirm .success-icon i {
  font-size: 69px;
  color: #17d053;
}
.email-confirm .btn {
  min-width: 200px;
}
.notifications {
  padding: 0;
  border: 1px solid #eef1fe;
  box-shadow: 0 4px 4px #eef1fe;
  border-radius: 10px !important;
}
.notifications .media-body {
  width: 100%;
}
.notifications .media-body h6 {
  margin-bottom: 3px;
}
.notifications .notification-time {
  font-size: 12px;
  line-height: 1.35;
  float: right;
}
.notifications .media {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}
.notifications .media:last-child {
  border-bottom: none;
}
.notifications .media a {
  display: block;
  padding: 10px 15px;
  border-radius: 2px;
}
.notifications .media a:hover {
  background-color: #fafafa;
}
.notifications .media > .avatar {
  margin-right: 10px;
  width: auto;
  height: auto;
}
.notifications .media > .avatar > img {
  width: 46px;
  height: 46px;
  border-radius: 5px;
  margin-right: 8px;
}
.notifications .media-list .media-left {
  padding-right: 8px;
}
.main-drop .dropdown-menu {
  min-width: 163px;
  border: 1px solid #eef1fe;
  box-shadow: 0 4px 4px #eef1fe;
  border-radius: 5px;
  transform: translate(0px, 45px) !important;
}
.topnav-dropdown-header {
  border-bottom: 1px solid #eef1fe;
  text-align: center;
}
.topnav-dropdown-header,
.topnav-dropdown-footer {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}
.topnav-dropdown-footer {
  border-top: 1px solid #eee;
}
.topnav-dropdown-footer a {
  display: block;
  text-align: center;
  color: #333;
}
.display-none {
  display: none;
}
.user-menu.nav > li > a .badge {
  background-color: #ef3737;
  display: block;
  font-size: 10px;
  font-weight: 700;
  height: 5px;
  width: 5px;
  padding: 0;
  color: #fff;
  position: absolute;
  right: 15px;
  top: 20px;
  border-radius: 50%;
}
.user-menu.nav > li > a > i {
  font-size: 15px;
  line-height: 60px;
}
.user-menu.nav > li > a > svg {
  font-size: 1.5rem;
  line-height: 60px;
}
.noti-details {
  color: #7e84a3;
  margin-bottom: 0;
}
.noti-title {
  color: #0ce0ff;
}
.notifications .noti-content {
  height: 260px;
  width: 420px;
  overflow-y: auto;
  position: relative;
}
.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.notifications ul.notification-list > li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
  border-bottom: none;
}
.notifications ul.notification-list > li a {
  display: block;
  padding: 10px 15px;
  border-radius: 2px;
}
.notifications ul.notification-list > li a:hover {
  background-color: #fafafa;
}
.notifications ul.notification-list > li .list-item {
  border: 0;
  padding: 0;
  position: relative;
}
.topnav-dropdown-header .notification-title {
  color: #333;
  display: block;
  float: left;
  font-size: 16px;
  font-weight: 600;
}
.topnav-dropdown-header .clear-noti {
  color: #131523;
  float: right;
  font-size: 12px;
  text-transform: uppercase;
}
.noti-time {
  margin: 0;
}
.dash-title {
  font-size: 18px;
  font-weight: 400;
  color: #7e84a3;
  margin-bottom: 2px;
}
.dash-widget-icon {
  align-items: center;
  border-radius: 3px;
  color: #fff;
  display: inline-flex;
  font-size: 1.875rem;
  height: 52px;
  justify-content: center;
  text-align: center;
  width: 52px;
}
.dash-counts p {
  font-weight: 700;
}
.dash-count {
  font-size: 24px;
  margin-left: 15px;
}
.dash-widget-info h3 {
  margin-bottom: 10px;
}
.dash-widget-header {
  display: flex;
  display: -ms-flexbox;
}
.trade-level {
  color: #7e84a3;
  text-align: right;
  font-size: 14px;
  padding-top: 8px;
}
.income-rev {
  background: #e5f6fd;
  font-size: 14px;
  padding: 10px;
  display: inline-block;
  margin-bottom: 15px;
}
.income-rev span {
  font-weight: 700;
}
.table .user-name {
  color: #131523;
  font-weight: 600;
}
.disease-name {
  font-weight: 600;
  color: #1b5a90;
}
.bg-badge-grey {
  background: #f5f6fa;
}
.table-rating i {
  color: #ffbc00;
}
.avatar-pos {
  position: relative;
}
.empty-user {
  width: 42px;
  height: 42px;
  background: #eef1fe;
  border-radius: 5px;
  margin-right: 12px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}
.empty-user i {
  color: #7e84a3;
  font-size: 20px;
}
.emp-title {
  font-size: 24px;
  font-weight: 700;
  color: #1b5a90;
}
.activity-feed {
  list-style: none;
  margin-bottom: 0;
  margin-left: 5px;
  padding: 0;
}
.recent-card,
.recent-card .card-header {
  background: #f4f6ff;
}
.pres-list {
  background: #fff;
  border: 1px solid #eef1fe;
  border-radius: 5px;
  margin-bottom: 9px;
}
.pres-head {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eef1fe;
}
.pres-head h6 {
  margin-bottom: 0;
}
.pres-head p {
  color: #f0142f;
  font-weight: 600;
}
.pres-body {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  padding: 10px;
}
.pres-body p {
  margin-bottom: 2px;
  color: #7e84a3;
}
.pres-body > div {
  padding-right: 10px;
}
.pres-body h6 {
  font-size: 14px;
  margin-bottom: 0;
}
.flag-box {
  width: 54px;
  height: 54px;
  background: #fff;
  border: 1px solid #e6e9f4;
  border-radius: 4px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.flag-box img {
  width: 32px;
  height: 32px;
  margin-right: 0 !important;
}
.country-item {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.country-item:last-child {
  margin-bottom: 0;
}
.con-name {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}
.con-revenue p {
  margin-bottom: 3px;
}
.con-revenue h6 {
  font-weight: 600;
  font-size: 16px;
  color: #f0142f;
  margin-bottom: 0;
}
.speciality-item {
  padding: 16px;
}
.spl-items {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #eef1fe;
  box-shadow: 0 7px 10px #eef1fe;
  border-radius: 5px;
  margin-bottom: 16px;
}
.spl-items a {
  padding: 30px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spl-items:hover {
  background: #0ce0ff;
}
.spl-items i {
  color: #7e84a3;
  font-size: 30px;
  margin-bottom: 12px;
}
.spl-items a:hover i,
.spl-items a:hover h6 {
  color: #fff;
}
.spl-items h6 {
  color: #7e84a3;
  text-align: center;
  margin-bottom: 0;
}
.doc-table.table > tbody > tr > td {
  padding: 13px;
}
.appointment-item {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #eef1fe;
  box-shadow: 0 7px 10px #eef1fe;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 30px;
}
.app-head {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e9f4;
  padding-bottom: 10px;
}
.app-head p {
  margin-bottom: 0;
}
.app-head .con-time {
  background: #ff8e3e;
  border-radius: 80px;
  padding: 4px 8px;
  font-weight: 600;
  color: #fff;
}
.app-user {
  border-bottom: 1px solid #e6e9f4;
}
.app-user .app-img {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  margin: 10px 0;
}
.app-user .app-img:last-child {
  border-left: 1px solid #e6e9f4;
}
.app-user .app-img img {
  width: 42px;
  height: 42px;
  border-radius: 5px;
  margin-right: 8px;
}
.app-user .app-name h6 {
  color: #7e84a3;
  margin-bottom: 1px;
}
.app-user .app-name p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 110px;
}
.app-info {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  border-bottom: 1px solid #e6e9f4;
  padding: 10px 0;
}
.app-info p {
  color: #7e84a3;
  margin-bottom: 3px;
}
.app-info h6 {
  font-size: 14px;
  font-weight: 600;
}
.app-footer {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  padding-top: 14px;
}
.app-mode p {
  color: #7e84a3;
}
.app-footer h6 {
  color: #f0142f;
  font-weight: 700;
  margin-bottom: 0;
}
.app-mode {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}
.app-mode p {
  margin: 0 10px 0 0;
}
.app-mode .mode-box {
  width: 39px;
  height: 39px;
  background: #fff;
  border: 1px solid #eef1fe;
  box-sizing: border-box;
  box-shadow: 0 4px 24px #eef1fe;
  border-radius: 4px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}
.app-mode .mode-box i {
  font-size: 20px;
}
.slide-nav-3 button.owl-next,
.slide-nav-3 button.owl-prev {
  background: 0 0;
  top: 4px;
  cursor: pointer;
  border: 0;
  color: #131523;
  margin-bottom: 15px;
  border-radius: 8px;
}
.speciality-item {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
}
.spl-name {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}
.spl-box {
  width: 53px;
  height: 53px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #eef1fe;
  box-shadow: 0 5px 7px #eef1fe;
  border-radius: 5px;
  margin-right: 10px;
}
.spl-count h6 {
  margin-bottom: 2px;
}
.spl-consult p {
  margin-bottom: 3px;
}
.spl-consult h6 {
  color: #0ce0ff;
  margin-bottom: 0;
}
.speciality-item:hover {
  background: #fff;
  box-shadow: 0 0 24px #eef1fe;
}
.upcomimg-app .owl-theme .owl-nav .owl-prev,
.upcomimg-app .owl-theme .owl-nav .owl-next {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  display: inline-block;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  bottom: 0;
  background-color: #fff;
}
.upcomimg-app .owl-theme .owl-nav .owl-next {
  right: 0;
  left: auto;
}
.upcomimg-app {
  width: 100%;
}
.upcomimg-app .owl-carousel {
  padding: 0 15px;
}
.price-list {
  text-align: center;
}
.price-list a.active {
  background: #0ce0ff;
  border-radius: 5px;
  color: #131523;
  font-weight: 600;
}
.price-list a {
  color: #131523;
  display: block;
}
.price-list a span {
  display: block;
}
.appointment-items {
  background: #f4f6ff;
  border: 1px solid #f5f6fa;
  border-radius: 3px;
}
.app-time p {
  font-weight: 600;
  color: #1b5a90;
  margin-bottom: 15px;
}
.app-infos p {
  margin-bottom: 4px;
}
.app-infos h6 {
  font-size: 16px;
  margin-bottom: 0;
  color: #f0142f;
}
.mode-app {
  width: 45px;
  height: 39px;
  background: #fff;
  border: 1px solid #eef1fe;
  box-shadow: 0 4px 24px #eef1fe;
  border-radius: 4px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}
.appointment-items .app-user {
  border-bottom: 0;
  margin-bottom: 10px;
}
.appointment-items .app-img {
  margin: 0 0 20px;
}
.appointment-items {
  padding: 10px;
  margin-bottom: 5px;
}
.pat-card .card-body {
  padding: 10px;
}
.pat-card p {
  margin-bottom: 2px;
}
.pat-card h3 {
  font-size: 28px;
  margin-bottom: 5px;
}
.pat-card .trade-level {
  text-align: left;
}
.cons-card {
  padding: 10px;
}
.cons-card h6 {
  font-size: 14px;
}
.upcoming-content {
  height: 371px;
  overflow-y: auto;
  padding-right: 5px;
}
.upcoming-content::-webkit-scrollbar {
  width: 3px;
  background: #1b5a90;
  height: 3px;
}
.upcoming-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e7e7e7;
  border-radius: 10px;
}
.upcoming-content::-webkit-scrollbar-thumb {
  background: #0ce0ff;
  border-radius: 10px;
}
.upcoming-content::-webkit-scrollbar-thumb:hover {
  background: #0ce0ff;
}
.selectBox-cont {
  height: 220px;
  overflow-y: auto;
  padding-right: 5px;
}
.selectBox-cont::-webkit-scrollbar {
  width: 3px;
  background: #eef1fe;
  height: 3px;
}
.selectBox-cont::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e7e7e7;
  border-radius: 10px;
}
.selectBox-cont::-webkit-scrollbar-thumb {
  background: #1b5a90;
  border-radius: 10px;
}
.selectBox-cont::-webkit-scrollbar-thumb:hover {
  background: #1b5a90;
}
.contentmodal .select2-container .select2-selection--single {
  height: 51px;
  background: #f5f6fa;
}
.contentmodal
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 50px;
}
.contentmodal
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 49px;
}
.app-status p {
  margin-bottom: 2px;
}
.app-status h6 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 7px;
}
.num-circle {
  width: 24px;
  height: 24px;
  font-size: 14px;
  color: #fff;
  background: #0ce0ff;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
}
.table > tbody > tr.speciality-item > td {
  padding: 0 5px;
}
.recent-tab .table > tbody > tr > td {
  padding: 0;
}
.activity-feed .feed-item {
  border-left: 2px solid #e4e8eb;
  padding-bottom: 19px;
  padding-left: 20px;
  position: relative;
}
.activity-feed .feed-item:last-child {
  border-color: transparent;
}
.activity-feed .feed-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #0ce0ff;
}
.activity-feed .feed-item .feed-date {
  display: block;
  position: relative;
  color: #777;
  text-transform: uppercase;
  font-size: 13px;
}
.activity-feed .feed-item .feed-text {
  color: #777;
  position: relative;
}
.activity-feed .feed-item .feed-text a {
  color: #333;
  font-weight: 600;
}
.modal-icon i {
  font-size: 42px;
  color: #6c757d;
}
.modal-footer {
  border-top: 0;
  padding-top: 0;
  padding-bottom: 0.75rem;
}
.add-remove i {
  font-size: 18px;
  cursor: pointer;
}
.delete-wrap {
  margin-bottom: 55px;
}
.delete-wrap h2 {
  font-size: 24px;
  margin-bottom: 25px;
}
.del-icon {
  margin-bottom: 23px;
}
.del-icon i {
  font-size: 60px;
  color: #f0142f;
  margin-bottom: 23px;
}
.delete-wrap p {
  font-size: 24px;
  margin-bottom: 20px;
}
.text-black {
  color: #131523;
}
.actions a + a {
  border-left: 1px solid #eef1fe;
  padding-left: 10px;
  margin-left: 10px;
}
.btn-add {
  padding: 6px 19px;
  font-weight: 600;
  font-size: 16px;
}
.change-photo-btn input.upload {
  bottom: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100px;
  width: 100%;
}
.change-photo-btn {
  color: #7e84a3;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  padding: 15px;
  position: relative;
  transition: 0.3s;
  text-align: center;
  height: 100px;
  background: #fff;
  border: 1px dashed #e1e1e1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  margin: auto;
  margin-bottom: 10px;
}
.add-wrap .form-control {
  background: #f5f6fa;
  border: 1px solid #e6e9f4;
}
.file-name {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}
.file-name a {
  line-height: 0;
  padding-left: 5px;
}
.btn-save {
  min-width: 210px !important;
}
.file-icon .form-control {
  padding: 6px 12px;
  line-height: 2.6;
  min-height: 51px;
}
.select2-container.select2-container--default.select2-container--open {
  z-index: 5000;
}
.setting-wrap {
  background: rgba(12, 224, 255, 0.03);
  padding: 15px;
  margin: 10px 0 15px;
}
.setting-wrap h5 {
  font-weight: 400;
  margin-bottom: 6px;
}
.setting-wrap p {
  color: #7e84a3;
}
.setting-info .form-control {
  min-height: 51px;
  background: #f5f6fa;
}
.custom-file-upload {
  background-color: #fff;
  border: 1px solid #dde2ff;
  border-radius: 5px;
  display: inline-flex;
  transition: border-color 0.3s;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  margin-top: 0;
  height: 51px;
  color: #000;
  position: relative;
}
.custom-file-upload .file-upload-text {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  padding-left: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.custom-file-upload input {
  display: none;
}
.change-user {
  padding: 14px 21px;
  background: #f5f6fa;
  border-radius: 4px 0 0 4px;
  font-size: 14px;
  color: #131523;
  position: absolute;
  left: 0;
}
.img-text {
  padding-top: 5px;
}
.login-option {
  margin: 25px 0;
}
.login-option h5 {
  font-size: 14px;
  font-weight: 400;
  color: #7e84a3;
}
.login-option h6 {
  font-weight: 400;
  color: #000;
}
.login-option p {
  color: #000;
  padding-left: 16px;
}
.setting-accordion .accordion-item {
  margin-bottom: 18px;
  background: #fff;
  border: 1px solid #eef1fe;
  border-radius: 0;
}
.accordion-button:focus {
  box-shadow: none;
  border-color: #eef1fe;
}
.accordion-header {
  cursor: pointer;
}
.accordion-button:not(.collapsed) {
  background: #fff;
  color: #000;
  border-bottom: 1px solid #eef1fe;
  box-shadow: none;
}
.accordion-button::after {
  content: none;
}
.accordion-button,
.accordion-body {
  padding: 20px;
}
.setting-accordion .accordion-header .accord-title {
  border-left: 1px solid #d9d9d9;
  font-size: 16px;
  padding-left: 20px;
}
.setting-accordion .accordion-header p {
  color: #7e84a3;
}
.setting-accordion .toggle-switch {
  margin-right: 20px;
}
.payment-item {
  padding: 16px;
  border-bottom: 1px solid #dfe5fc;
}
.payment-item:last-child {
  border: 0;
}
.payment-item .head-title {
  font-size: 14px;
  text-align: right;
  margin-bottom: 0;
}
.payment-item .form-control {
  background: #f5f6fa;
  border: 1px solid #e6e9f4;
  padding: 6px 12px;
  height: 51px;
}
.settings-menu ul {
  display: block;
  padding: 0;
}
.settings-menu ul li + li {
  margin-top: 15px;
}
.settings-menu ul li a {
  color: #455560;
  padding: 0;
  border: 0 !important;
  display: inline-block;
}
.settings-menu ul li a:hover,
.settings-menu ul li a:focus {
  background-color: transparent !important;
  border: 0 !important;
}
.settings-menu ul li a.active {
  color: #0ce0ff !important;
  border: 0;
}
.settings-menu ul li i {
  margin-right: 10px;
  font-size: 20px;
  min-width: 25px;
}
.setting-info h5 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
  color: #000;
}
.setting-info p {
  color: #7e84a3;
}
.submit-sec {
  text-align: right;
  padding-top: 20px;
}
.submit-sec .btn {
  min-width: 146px;
  margin-bottom: 10px;
}
.submit-sec .btn + .btn {
  margin-left: 10px;
}
.acc-wrap {
  border-bottom: 1px solid #eef1fe;
  margin-bottom: 30px;
}
.acc-wrap h6 {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-bottom: 6px;
}
.acc-wrap p {
  color: #7e84a3;
  margin-bottom: 20px;
}
.form-focus {
  min-height: 51px;
  position: relative;
}
.form-focus .focus-label {
  font-size: 14px;
  font-weight: 400;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate3d(0, 22px, 0) scale(1);
  -ms-transform: translate3d(0, 22px, 0) scale(1);
  -o-transform: translate3d(0, 22px, 0) scale(1);
  transform: translate3d(0, 22px, 0) scale(1);
  transform-origin: left top;
  transition: 240ms;
  left: 12px;
  top: -8px;
  z-index: 1;
  color: #131523;
  margin-bottom: 0;
}
.form-focus.focused .focus-label {
  opacity: 1;
  top: -18px;
  font-size: 12px;
  z-index: 1;
}
.form-focus.focused .form-control {
  background: #fff;
}
.form-focus .form-control:focus ~ .focus-label,
.form-focus .form-control:-webkit-autofill ~ .focus-label {
  opacity: 1;
  font-weight: 400;
  top: -18px;
  font-size: 12px;
  z-index: 1;
}
.form-focus .form-control {
  min-height: 51px;
  padding: 21px 12px 6px;
  background: #f5f6fa;
}
.form-focus .form-control::-webkit-input-placeholder {
  color: transparent;
  transition: 240ms;
}
.form-focus .form-control:focus::-webkit-input-placeholder {
  transition: none;
}
.form-focus.focused .form-control::-webkit-input-placeholder {
  color: #131523;
}
.form-focus.select-focus .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -20px;
  font-size: 12px;
  z-index: 1;
}
.form-focus .select2-container .select2-selection--single {
  border: 1px solid #e6e9f4;
  background: #f5f6fa;
  height: 51px;
}
.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 48px;
  right: 7px;
}
.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #131523;
  border-style: solid;
  border-width: 0 2px 2px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.form-focus
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #131523;
  border-width: 2px 0 0 2px;
  padding: 3px;
}
.form-focus
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 12px;
  padding-top: 5px;
}
.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #131523;
  font-size: 14px;
  font-weight: 400;
  line-height: 38px;
}
.form-focus
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #eef1fe;
}
.app-listing {
  display: flex;
  display: -ms-flexbox;
  justify-content: end;
  margin-bottom: 25px;
}
.app-listing .import-list,
.app-listing .SortBy {
  margin-left: 15px;
}
.import-list {
  border: 1px solid #eef1fe;
  border-radius: 7px;
  padding: 6px 20px;
}
.import-list a {
  color: #131523;
}
.table td.desc-info {
  width: 190px;
  white-space: pre-wrap;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.invoice-item .invoice-logo {
  margin-bottom: 30px;
}
.invoice-item .invoice-logo img {
  width: auto;
  max-height: 52px;
}
.invoice-item .invoice-text h2 {
  color: #272b41;
  font-size: 36px;
  font-weight: 600;
}
.invoice-item .invoice-details {
  text-align: right;
  font-weight: 500;
}
.invoice-item .invoice-details strong {
  color: #272b41;
}
.invoice-item .invoice-details-two {
  text-align: left;
}
.invoice-item .invoice-text {
  padding-top: 42px;
  padding-bottom: 36px;
}
.invoice-item .invoice-text h2 {
  font-weight: 400;
}
.invoice-info {
  margin-bottom: 30px;
}
.invoice-info p {
  margin-bottom: 0;
}
.invoice-info.invoice-info2 {
  text-align: right;
}
.invoice-item .customer-text {
  font-size: 18px;
  color: #272b41;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
}
.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
  color: #272b41;
  font-weight: 600;
  padding: 10px 20px;
  line-height: inherit;
}
.invoice-table tr td,
.invoice-table-two tr td {
  font-weight: 500;
}
.invoice-table-two {
  margin-bottom: 0;
}
.invoice-table-two tr th,
.invoice-table-two tr td {
  border-top: 0;
}
.invoice-table-two tr td {
  text-align: right;
}
.invoice-info h5 {
  font-size: 16px;
  font-weight: 500;
}
.other-info {
  margin-top: 10px;
}
.file {
  visibility: hidden;
  position: absolute;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: -inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.toggle-switch {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.toggle-switch:not(.form-group) {
  margin-bottom: 0;
}
.toggle-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.toggle-switch-content {
  -ms-flex: 1;
  flex: 1;
  margin-left: 0.5rem;
}
.toggle-switch-label {
  position: relative;
  display: block;
  width: 25px;
  height: 14px;
  background-color: #eef1fe;
  box-shadow: 0 4px 4px rgba(238, 241, 254, 0.3);
  border-radius: 10px;
  transition: 0.3s;
}
.toggle-switch-indicator {
  position: absolute;
  left: 0.125rem;
  bottom: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  -webkit-transform: initial;
  transform: initial;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
  border-radius: 50%;
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  transition: 0.3s;
}
.toggle-switch-input:checked + .toggle-switch-label {
  background-color: #1b5a90;
}
.toggle-switch-input:checked + .toggle-switch-label .toggle-switch-indicator {
  -webkit-transform: translate3d(10px, 50%, 0);
  transform: translate3d(10px, 50%, 0);
}
.toggle-switch-input.is-valid + .toggle-switch-label {
  background-color: #00c9a7;
}
.toggle-switch-input.is-invalid + .toggle-switch-label {
  background-color: #ed4c78;
}
.toggle-switch-input:disabled + .toggle-switch-label {
  background-color: rgba(231, 234, 243, 0.5);
}
.toggle-switch-input:checked:disabled + .toggle-switch-label {
  background-color: rgba(55, 125, 255, 0.5);
}
.toggle-switch-sm .toggle-switch-label {
  width: 2.5rem;
  height: 1.6125rem;
}
.toggle-switch-sm .toggle-switch-indicator {
  width: 1.20938rem;
  height: 1.20938rem;
}
.toggle-switch-sm
  .toggle-switch-input:checked
  + .toggle-switch-label
  .toggle-switch-indicator {
  -webkit-transform: translate3d(0.81094rem, 50%, 0);
  transform: translate3d(0.81094rem, 50%, 0);
}
.cal-icon {
  position: relative;
  width: 100%;
}
.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: "font awesome 5 free";
  font-weight: 900;
  font-size: 15px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 10px;
}
.profile-cover {
  position: relative;
  padding: 1.75rem 2rem;
  border-radius: 0.75rem;
  height: 10rem;
}
.profile-cover-wrap {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 10rem;
  background-color: #e7eaf3;
  border-radius: 0.75rem;
}
.profile-cover-img {
  width: 100%;
  height: 10rem;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: top;
  border-radius: 0.75rem;
}
.cover-content {
  position: relative;
  z-index: 1;
  padding: 1rem 2rem;
  position: absolute;
  bottom: 0;
  right: 0;
}
.custom-file-btn {
  position: relative;
  overflow: hidden;
}
.custom-file-btn-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
}
hr {
  background-color: #eef1fe;
  opacity: 1;
}
.profile-cover-avatar {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  width: 93px;
  height: 93px;
}
.profile-cover-avatar input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 33, 68, 0.25);
  border-radius: 50%;
  transition: 0.2s;
}
.avatar-edit {
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  background: #0ce0ff;
  border-radius: 50%;
  transition: 0.2s;
}
.avatar-edit svg {
  width: 18px;
}
.card-table
  div.table-responsive
  > div.dataTables_wrapper
  > div.row:first-child {
  padding: 1.5rem 1.5rem 0;
}
.card-table div.table-responsive > div.dataTables_wrapper > div.row:last-child {
  padding: 0 1.5rem 1.5rem;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 9px;
}
#filter_inputs {
  display: none;
}
.input-label {
  display: block;
  color: #1e2022;
  font-size: 0.875rem;
}
.submit-section {
  text-align: center;
  margin-top: 25px;
}
.submit-btn {
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  min-width: 146px;
  padding: 6px 20px;
}
.card-body-height {
  height: 28rem;
  overflow: hidden;
  overflow-y: auto;
}
.profile-cover-avatar .avatar-img {
  display: block;
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
  border-radius: 3px;
}
.profile-info h4 {
  margin-bottom: 30px;
}
.profile-list {
  background: #fff;
  box-shadow: 0 14px 34px rgba(249, 249, 249, 0.25);
  border-radius: 4px;
  padding: 20px;
}
.pro-title {
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eef1fe;
}
a.edit-pro {
  font-size: 16px;
  color: #0ce0ff;
}
.profile-list h6 {
  font-weight: 400;
}
.profile-list p {
  color: #7e84a3;
  margin-bottom: 10px;
}
.profile-list h5 {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
}
.profile-detail {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  margin-bottom: 25px;
}
.profile-detail .pro-name {
  padding-left: 20px;
}
.profile-detail .pro-name p {
  margin-bottom: 5px;
}
.profile-detail .pro-name h4,
.profile-detail .profile-cover-avatar {
  margin-bottom: 5px;
}
.profile-detail .edit-pro {
  margin-left: auto;
}
.social-icon {
  list-style: none;
  padding: 0;
  margin: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}
.social-icon li {
  display: inline-block;
  padding-right: 10px;
}
.social-icon li a {
  background: #fff;
  border: 1px solid #0ce0ff;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  color: #1b5a90;
  margin: 0;
  font-size: 14px;
  transition: all 0.4s ease 0s;
}
.social-icon li a:hover {
  color: #1b5a90;
  opacity: 0.8;
  transition: all 0.4s ease 0s;
}
.blog {
  background: #f5f6fa;
  border-radius: 18px;
  margin-bottom: 30px;
  padding: 15px;
  position: relative;
}
.grid-blog .blog-image {
  margin-bottom: 20px;
}
.blog-image,
.blog-image > a,
.blog-image img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}
.blog-image img {
  border-radius: 10px;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.blog-content {
  position: relative;
  margin-bottom: 10px;
}
.grid-blog .entry-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: 0;
}
.grid-blog .entry-meta li {
  margin-bottom: 15px;
}
.entry-meta li {
  display: inline-block;
  margin-right: 15px;
}
.grid-blog .post-author {
  width: 189px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.back-btn {
  font-weight: 600;
  font-size: 16px;
  color: #1b5a90;
  display: inline-block;
  margin-bottom: 18px;
}
.post-author a {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}
.post-author img {
  border-radius: 5px;
  width: 42px;
  height: 42px;
  margin-right: 10px;
}
.grid-blog .blog-title {
  font-size: 14px;
  margin: 0 0 6px;
}
.grid-blog .blog-title a {
  color: #1b5a90;
}
.grid-blog .blog-content p {
  font-size: 12px;
  margin: 0 0 15px;
}
.post-title {
  display: block;
  color: #131523;
  font-weight: 600;
}
.post-date {
  font-size: 12px;
  color: #7e84a3;
}
.fav-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  background: #f5f6fa;
  border-radius: 6px;
  padding: 2px 5px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}
.fav-btn img {
  width: auto;
  margin-right: 5px;
}
.btn-blog {
  min-width: 156px;
}
.active-text {
  color: #1b5a90;
}
.modal.contentmodal .modal-content .submit-section .btn.btn-download {
  min-width: 200px;
}
.blog-single-post .blog-image {
  margin-bottom: 20px;
}
.blog-single-post .blog-title {
  font-weight: 700;
  color: #1b5a90;
  margin-bottom: 25px;
}
.about-author-img {
  background-color: #fff;
  height: 62px;
  width: 62px;
}
.about-author-img img {
  border-radius: 5px;
  height: 62px;
  width: 62px;
}
.author-details {
  margin-left: 20px;
  flex: 1;
}
.about-author {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}
.author-details .blog-author-name {
  display: inline-block;
  color: #131523;
  font-weight: 600;
  margin-bottom: 5px;
}
.author-details .blog-author-name span {
  font-size: 12px;
  font-weight: 400;
  color: #7e84a3;
  padding-left: 3px;
}
.author-details p {
  font-size: 12px;
}
.blog-comments .comments-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.blog-comments .comments-list li {
  clear: both;
  padding-left: 57px;
}
.blog-comments .comments-list li .comment {
  margin-bottom: 20px;
}
.blog-comments .comments-list li .comment-author {
  left: 0;
  position: absolute;
}
.blog-comments .comments-list li img.avatar {
  height: 42px;
  width: 42px;
  border-radius: 5px;
}
.blog-comments .comment-btn {
  color: #0ce0ff;
  display: inline-block;
  font-size: 12px;
}
.blog-comments .comment-btn img {
  width: auto !important;
}
.blog-author-name {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.blog-date {
  color: #7e84a3;
  font-size: 12px;
  padding-left: 15px;
}
.comments-list .comment-block p {
  font-size: 12px;
  margin-bottom: 10px;
}
.social-share {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
}
.social-share > li {
  display: inline-block;
  float: left;
  margin-left: 10px;
  text-align: center;
}
.social-share > li:first-child {
  margin-left: 0;
}
.social-share > li > a {
  border: 1px solid #0ce0ff;
  border-radius: 6px;
  color: #1b5a90;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  height: 40px;
  width: 40px;
}
.social-share > li > a:hover {
  background-color: #1b5a90;
  color: #0ce0ff;
  border-color: #0ce0ff;
}
.post-list ul {
  margin: 0 0 20px;
  padding-left: 0;
  list-style: none;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  flex-wrap: wrap;
}
.post-list ul li {
  font-size: 12px;
  color: #7e84a3;
  margin-bottom: 5px;
  margin-right: 30px;
}
.post-list ul li i {
  padding-right: 5px;
}
.post-list ul li:last-child {
  margin-right: 0;
}
.post-author img {
  border-radius: 5px;
  width: 36px;
  height: 36px;
  margin-right: 10px;
}
.post-author span {
  font-size: 14px;
  color: #7e84a3;
}
.save-btn {
  font-weight: 600;
  font-size: 16px;
  padding: 6px 75px;
}
.upload-file {
  margin-bottom: 20px;
}
.upload-file h6 {
  font-weight: 400;
  margin-bottom: 15px;
}
.upload-file img {
  border-radius: 5px;
  margin-bottom: 6px;
}
.upload-file p {
  font-size: 12px;
  color: #7e84a3;
}
.up-img {
  position: relative;
  padding-left: 11px;
}
.up-img .close-icon {
  position: absolute;
  top: -9px;
}
body.darkmode {
  background-color: #141432;
  color: #97a2d2;
}
.darkmode h1,
.darkmode h2,
.darkmode h3,
.darkmode h4,
.darkmode h5,
.darkmode h6 {
  color: #97a2d2;
}
.darkmode .header {
  background: #1d1d42;
  border-bottom: 1px solid #1d1d42;
}
.darkmode .sidebar {
  background-color: #1d1d42;
  box-shadow: none;
}
.darkmode .menu-title span {
  background: #1d1d42;
}
.darkmode .menu-title:before {
  border-top: 1px solid #353570;
}
.darkmode .menu-title {
  color: #a6abce;
}
.darkmode .sidebar-menu li a {
  color: #97a2d2;
}
.darkmode .header .header-left {
  border-bottom: 1px solid #353570;
}
.darkmode .sidebar-menu li.active > a {
  color: #0ce0ff;
}
.darkmode .card {
  background: #1d1d42;
  border: 1px solid #353570;
  box-shadow: none;
}
.darkmode .dash-title {
  color: #97a2d2;
}
.darkmode .card .card-header {
  background: #1d1d42;
  border-bottom: 1px solid #353570;
}
.darkmode .select2-container .select2-selection--single {
  border: 1px solid #353570;
  background: #141432;
}
.darkmode
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #97a2d2;
}
.darkmode
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888ea8;
}
.darkmode .appointment-item {
  background: #1d1d42;
  border: 1px solid #353570;
  box-shadow: none;
}
.darkmode .table {
  color: #888ea8;
}
.darkmode .table .thead-light th {
  background-color: #141432;
  color: #97a2d2;
}
.darkmode .hover-table > tbody > tr:hover,
.darkmode .speciality-item:hover {
  background: #141432;
  box-shadow: none;
}
.darkmode .table .user-name {
  color: #97a2d2;
}
.darkmode tbody,
.darkmode td,
.darkmode tfoot,
.darkmode th,
.darkmode thead,
.darkmode tr {
  border-color: #353570;
}
.darkmode .app-head,
.darkmode .app-user,
.app-info {
  border-bottom: 1px solid #353570;
}
.darkmode .app-mode .mode-box {
  background: #353570;
  border: 1px solid #353570;
  box-shadow: none;
}
.darkmode .spl-box {
  background: #eef1fe;
  border: 1px solid #97a2d2;
  box-shadow: none;
}
.darkmode .bg-badge-grey {
  background: 0 0;
}
.darkmode .appointment-items {
  background: #141432;
  border: 1px solid #353570;
}
.darkmode .mode-app {
  background: #353570;
  border: 1px solid #353570;
  box-shadow: none;
}
.darkmode .price-list a {
  color: #97a2d2;
}
.darkmode .price-list a.active {
  color: #fff;
}
.darkmode .app-user .app-img:last-child {
  border-left: 1px solid #353570;
}
.darkmode .upcomimg-app .owl-theme .owl-nav .owl-prev,
.darkmode .upcomimg-app .owl-theme .owl-nav .owl-next {
  background-color: transparent;
}
.darkmode .spl-items {
  background: #1d1d42;
  border: 1px solid #353570;
  box-shadow: none;
}
.darkmode .spl-items:hover {
  background: #141432;
}
.darkmode .spl-items a:hover i,
.spl-items a:hover h6 {
  color: #97a2d2;
}
.darkmode .pres-list {
  background: #141432;
  border: 1px solid #353570;
}
.darkmode .pres-head {
  border-bottom: 1px solid #353570;
}
.darkmode .pres-body p {
  color: #97a2d2;
}
.darkmode .btn-white {
  background: #141432;
  border-color: #141432;
}
.darkmode hr {
  background-color: #353570;
}
.darkmode .bookingrange.btn-white {
  color: #97a2d2;
  border: 1px solid #353570;
}
.darkmode .ico i:first-child {
  border-left: 1px solid #353570;
  border-right: 1px solid #353570;
}
.darkmode .ico i {
  color: #97a2d2;
}
.darkmode .list-links li.active a {
  background: #353570;
  border: 1px solid #1d1d42;
  color: #97a2d2;
}
.darkmode .list-links li a {
  background: #1d1d42;
  border: 1px solid #1d1d42;
  color: #97a2d2;
}
.darkmode .list-links li a:hover {
  background: #353570;
}
.darkmode .multipleSelection .selectBox,
.darkmode .SortBy .selectBoxes {
  background: #1d1d42;
  border: 1px solid #353570;
}
.darkmode .multipleSelection #checkBoxes,
.darkmode .SortBy #checkBox {
  background: #141432;
  border: 1px solid #353570;
  box-shadow: none;
}
.darkmode .custom_check {
  color: #97a2d2 !important;
}
.darkmode .custom_check .checkmark {
  border: 1px solid #353570;
  background: #1d1d42;
}
.darkmode .custom_check .checkmark::after {
  color: #1d1d42;
}
.darkmode .lab-title {
  color: #0ce0ff;
}
.darkmode .bg-grey {
  background: #1d1d42 !important;
}
.darkmode .custom_check input:checked ~ .checkmark {
  background-color: #0ce0ff;
  border-color: #0ce0ff;
}
.darkmode .form-control {
  border: 1px solid #353570;
  background: 0 0;
}
.darkmode .form-focus.focused .form-control::-webkit-input-placeholder,
.darkmode .form-control::-webkit-input-placeholder {
  color: #97a2d2;
}
.darkmode .multipleSelection #checkBoxes .form-custom i {
  color: #97a2d2;
}
.darkmode .multipleSelection #checkBoxes::before,
.darkmode .SortBy #checkBox::before {
  border: 5px solid #1d1d42;
  border-color: transparent transparent #353570 #353570;
}
.darkmode .daterangepicker {
  background: #1d1d42;
  border: 1px solid #353570;
}
.darkmode .daterangepicker .ranges li {
  background: #171735;
  border: 1px solid #171735;
  color: #97a2d2;
}
.darkmode .daterangepicker .ranges li.active {
  background: #353570;
  border: 1px solid #353570;
  color: #97a2d2;
}
.darkmode .daterangepicker.show-calendar .ranges {
  background: #1d1d42;
}
.darkmode .daterangepicker .drp-buttons {
  border-top: 1px solid #1d1d42;
}
.darkmode .daterangepicker .calendar-table {
  background: #1d1d42;
  border-top: 1px solid #1d1d42;
}
.darkmode .daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #1d1d42;
  color: #97a2d2;
}
.darkmode .daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #353570;
  color: #97a2d2;
}
.darkmode .daterangepicker td.in-range {
  background-color: #353570;
  color: #97a2d2;
}
.darkmode .daterangepicker .calendar-table {
  border: 1px solid #1d1d42;
}
.darkmode .daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid #97a2d2;
  border-width: 0 2px 2px 0;
}
.darkmode .daterangepicker:before {
  border-bottom: 7px solid #2f2f64;
}
.darkmode .dataTables_wrapper .page-item.active .page-link {
  background: #1d1d42;
  border: 1px solid #1d1d42;
  color: #97a2d2;
}
.darkmode .page-item.disabled .page-link {
  background: #353570;
  border: 1px solid #353570;
  color: #97a2d2;
}
.darkmode .dataTables_wrapper .page-link {
  box-shadow: none;
}
.darkmode .dataTables_wrapper .previous .page-link,
.darkmode .dataTables_wrapper .next .page-link {
  background: 0 0;
  border: 0;
  color: #97a2d2;
}
.darkmode .spl-img {
  box-shadow: none;
}
.darkmode table.table td h2 a {
  color: #97a2d2;
}
.darkmode .flag-box {
  background: #eef1fe;
}
.darkmode .upcoming-content::-webkit-scrollbar-thumb,
.darkmode .upcoming-content::-webkit-scrollbar-thumb:hover {
  background: #141432;
}
.darkmode .app-time p {
  color: #bbdeff;
}
.darkmode .income-rev {
  background: #282855;
  color: #888ea8;
  border-radius: 3px;
}
.darkmode .select2-container--default .select2-selection--multiple {
  background-color: red;
}
.darkmode .user-menu.nav > li > a > i {
  color: #97a2d2;
}
.darkmode .user-menu.nav > li > a.dark-mode-toggle {
  border: 1px solid #353570;
  background: 0 0;
}
.darkmode .user-menu.nav > li > a.dark-mode-toggle i {
  background: 0 0;
}
.darkmode .user-menu.nav > li > a.dark-mode-toggle i.dark-mode.active {
  background: #0ce0ff;
  color: #fff;
}
.darkmode .user-menu.nav > li > a.dark-mode-toggle i.dark-mode,
.user-menu.nav > li > a.dark-mode-toggle i.light-mode {
  color: #0ce0ff;
}
.darkmode .dropdown-menu {
  background-color: #141432;
  border: 1px solid #353570;
  box-shadow: none;
}
.darkmode .dropdown-item {
  color: #97a2d2;
}
.darkmode .user-header {
  background-color: #1d1d42;
}
.darkmode .notifications:before {
  border-color: transparent transparent #141432 #141432;
}
.darkmode .user-header:before {
  border-color: transparent transparent #353570 #353570;
}
.darkmode .text-black {
  color: #97a2d2 !important;
}
.darkmode .custom_radio .checkmark {
  border: 1px solid #353570;
}
.darkmode .custom_radio .checkmark::after {
  background-color: #0ce0ff;
}
.darkmode .modal-content {
  background: #1d1d42;
  border: 1px solid #353570;
}
.darkmode .modal-header {
  border-bottom: 1px solid #353570;
}
.darkmode .form-focus .focus-label {
  color: #97a2d2;
}
.darkmode .form-focus.focused .form-control {
  background: #141432;
}
.darkmode .form-control:focus {
  color: #97a2d2;
}
.darkmode .change-photo-btn {
  background: #171735;
  border: 1px dashed #353570;
  color: #353570;
}
.darkmode .modal-backdrop.show {
  opacity: 0.7;
}
.darkmode .toggle-switch-indicator {
  background: #1b5a90;
}
.darkmode
  .toggle-switch-input:checked
  + .toggle-switch-label
  .toggle-switch-indicator {
  background: #fff;
}
.darkmode .toggle-switch-label {
  box-shadow: none;
}
.darkmode .modal.contentmodal .media {
  background-color: #141432;
  border: 1px solid #141432;
}
.darkmode .modal.contentmodal .member-wrapper h5 {
  border-bottom: 1px solid #353570;
}
.darkmode .modal.contentmodal .mem-info p {
  color: #888ea8;
}
.darkmode .rating-wrapper p {
  color: #888ea8;
}
.darkmode .sidebar-menu ul ul a {
  border-bottom: 1px solid #353570;
}
.darkmode .form-control {
  color: #97a2d2;
}
.darkmode .modal-content .form-control {
  background: #171735;
  border: 1px solid #353570;
}
.darkmode .custom-file-upload {
  border: 1px solid #353570;
  background: #171735;
}
.darkmode .change-user {
  background: #1d1d42;
  color: #97a2d2;
}
.darkmode .custom-file-upload .file-upload-text {
  color: #97a2d2;
}
.darkmode .setting-wrap {
  background: #1d1d42;
}
.darkmode .login-option p {
  color: #97a2d2;
}
.darkmode .preference-option p {
  color: #eef1fe;
}
.darkmode .accordion-button:not(.collapsed) {
  background: #1d1d42;
  border-bottom: 1px solid #353570 !important;
  border: 1px solid transparent;
}
.darkmode .setting-accordion .accordion-item {
  background: #1d1d42;
  border: 1px solid #353570;
}
.darkmode .setting-accordion .accordion-header .accord-title {
  color: #97a2d2;
}
.darkmode .accordion-header .accordion-button {
  background: #1d1d42;
}
.darkmode .payment-item {
  border-bottom: 1px solid #353570;
}
.darkmode .profile-list {
  background: #141432;
  box-shadow: none;
}
.darkmode .pro-title {
  border-bottom: 1px solid #353570;
}
.darkmode .social-icon li a {
  background: #141432;
  border: 1px solid #97a2d2;
  color: #97a2d2;
}
.darkmode .social-icon li a:hover {
  background: #353570;
  border: 1px solid #0ce0ff;
}
.darkmode .profile-list p {
  color: #888ea8;
}
.darkmode .acc-wrap {
  border-bottom: 1px solid #353570;
}
.darkmode .import-list {
  background: #0ce0ff;
  border: 1px solid #0ce0ff;
}
.darkmode .import-list a {
  color: #fff;
}
.darkmode .apexcharts-xaxis-texts-g text,
.darkmode .apexcharts-yaxis-texts-g text {
  fill: #97a2d2;
}
.darkmode .apexcharts-legend-text {
  color: #97a2d2 !important;
}
.darkmode .blog {
  background: #1d1d42;
}
.darkmode .grid-blog .blog-title a,
.darkmode .post-author span.post-title {
  color: #97a2d2;
}
.darkmode .post-author span.post-date,
.darkmode .blog-date,
.darkmode .account-subtitle,
.darkmode .grid-blog .blog-content p,
.darkmode .fav-btn,
.darkmode .error-box p {
  color: #888ea8;
}
.darkmode .pagination-tab .page-link {
  background: #353570;
  border: 1px solid #353570;
  color: #97a2d2;
  box-shadow: none;
}
.darkmode .pagination-tab .page-item.active .page-link {
  background: #1d1d42;
  border: 1px solid #1d1d42;
  color: #97a2d2;
}
.darkmode .pagination-tab .page-item.disabled .page-link,
.darkmode .pagination-tab .page-item:last-child .page-link {
  color: #97a2d2;
}
.darkmode .blog-single-post .blog-title {
  color: #97a2d2;
}
.darkmode .post-author span,
.darkmode .post-list ul li,
.darkmode .author-details .blog-author-name span {
  color: #2a5f9e;
}
.darkmode .author-details .blog-author-name {
  color: #97a2d2;
}
.darkmode .social-share > li > a {
  border: 1px solid #97a2d2;
  color: #97a2d2;
}
.darkmode .social-share > li > a:hover {
  background: #353570;
  border: 1px solid #0ce0ff;
  color: #0ce0ff;
}
.darkmode
  .form-focus
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #868ca6;
  border-width: 2px 0 0 2px;
}
.darkmode .upload-file p {
  color: #97a2d2;
}
.darkmode .login-wrapper {
  background-color: #141432;
}
.darkmode .social-login > a {
  border: 1px solid #97a2d2;
  background-color: #141432;
}
.darkmode .or-line {
  background-color: #353570;
}
.darkmode .span-or {
  color: #97a2d2;
  background-color: #141432;
}
.darkmode .select2-results__option {
  background-color: #141432;
  color: #97a2d2;
}
.darkmode
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #141432;
  color: #97a2d2;
}
.darkmode .select2-dropdown {
  border: 0 !important;
  border-top: 0;
}
.darkmode
  .select2-container--default
  .select2-results__option[aria-selected="true"] {
  background-color: #141432;
}
.darkmode .table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: #141432;
}
.darkmode .table-striped > tbody > tr:nth-of-type(odd) > * {
  color: #888ea8;
}
.darkmode .card-header .select2-container .select2-selection--single {
  border: 1px solid #141432;
  background: #141432;
}
.darkmode .table-hover tbody tr:hover {
  background-color: #141432;
}
.darkmode .table-hover > tbody > tr:hover > * {
  color: #888ea8;
}
.darkmode .form-select {
  background-color: transparent;
  border: 1px solid #353570;
  color: #97a2d2;
}
.darkmode .input-group-text {
  background: #1d1d42;
  border: 1px solid #2f2f64;
  color: #97a2d2;
}
.darkmode .input-group .btn-white {
  background: #1d1d42;
  border: 1px solid #2f2f64;
  color: #97a2d2;
}
.darkmode .card-body {
  background: #1d1d42;
}
.darkmode .section-header .section-title {
  color: #97a2d2;
}
.darkmode .nav-tabs .nav-item.show .nav-link,
.darkmode .nav-tabs .nav-link.active {
  background-color: #141432;
  border: 1px solid #2f2f64;
  color: #97a2d2;
}
.darkmode .nav-tabs.nav-tabs-solid {
  background-color: transparent;
}
.darkmode .nav-tabs > li > a {
  color: #97a2d2;
}
.darkmode .nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: 1px solid #2f2f64;
}
.darkmode .nav-tabs .nav-link:focus,
.darkmode .nav-tabs .nav-link:hover {
  background-color: #141432;
  border: 1px solid #2f2f64;
  color: #97a2d2;
}
.darkmode .card-footer {
  background-color: #1d1d42;
  border-top: 1px solid #2f2f64;
}
.darkmode .list-group-item {
  background-color: #1d1d42;
  color: #97a2d2;
}
.darkmode .search .results {
  background: #1d1d42;
  border: 1px solid #353570;
  box-shadow: none;
}
.darkmode .search .results:before {
  border: 7px solid #353570;
  border-color: transparent transparent #353570 #353570;
}
.darkmode .nav-tabs.user-menu .nav-link:focus,
.darkmode .nav-tabs.user-menu .nav-link:hover {
  background-color: transparent;
  border: 1px solid transparent !important;
}
.darkmode .page-link {
  background-color: #353570;
  border: 1px solid #2f2f64;
  color: #97a2d2;
}
.darkmode .topnav-dropdown-header {
  border-bottom: 1px solid #353570;
}
.darkmode .topnav-dropdown-header .notification-title {
  color: #97a2d2;
}
.darkmode .notifications ul.notification-list > li {
  border-bottom: 1px solid rgba(51, 120, 255, 0.09);
}
.darkmode .notifications ul.notification-list > li a:hover {
  background-color: #1d1d42;
}
.darkmode .noti-details {
  color: #888ea8;
}
@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }
  .avatar-xxl .border {
    border-width: 4px !important;
  }
  .avatar-xxl .rounded {
    border-radius: 12px !important;
  }
  .avatar-xxl .avatar-title {
    font-size: 42px;
  }
  .avatar-xxl.avatar-away:before,
  .avatar-xxl.avatar-offline:before,
  .avatar-xxl.avatar-online:before {
    border-width: 4px;
  }
}
@media (min-width: 992px) {
  #toggle_btn {
    color: #131523;
    font-size: 30px;
    height: 60px;
    line-height: 60px;
    padding: 0 0;
  }
  .mini-sidebar .header-left .logo img {
    height: auto;
    max-height: 40px;
    width: auto;
  }
  .mini-sidebar .header .header-left .logo {
    display: none;
  }
  .mini-sidebar .header-left .logo.logo-small {
    display: block;
  }
  .mini-sidebar .header .header-left {
    padding: 0 5px;
    width: 60px;
  }
  .mini-sidebar .header .header-left #toggle_btn i {
    transform: rotate(180deg);
    color: #7e84a3;
    padding: 0 7px;
  }
  .mini-sidebar .sidebar {
    width: 60px;
  }
  .mini-sidebar.expand-menu .sidebar {
    width: 200px;
  }
  .mini-sidebar .menu-title {
    visibility: hidden;
    white-space: nowrap;
  }
  .mini-sidebar.expand-menu .menu-title {
    visibility: visible;
  }
  .mini-sidebar .menu-title a {
    visibility: hidden;
  }
  .mini-sidebar.expand-menu .menu-title a {
    visibility: visible;
  }
  .modal-open.mini-sidebar .sidebar {
    z-index: 1051;
  }
  .mini-sidebar .sidebar .sidebar-menu ul > li > a span {
    display: none;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }
  .mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
    display: inline;
    opacity: 1;
  }
  .mini-sidebar .page-wrapper {
    margin-left: 78px;
  }
  .darkmode #toggle_btn {
    color: #97a2d2;
  }
}
@media (max-width: 1399px) {
  .spl-items i {
    margin-bottom: 17px;
  }
  .spl-items h6 {
    font-size: 13px;
  }
  .spl-items a {
    padding: 31px;
  }
  .app-user .app-img img {
    width: 38px;
    height: 38px;
  }
  .app-user .app-name h6 {
    font-size: 14px;
  }
  .app-user .app-name p {
    font-size: 11px;
  }
  h5 {
    font-size: 17px;
  }
}
@media (max-width: 991.98px) {
  .header .header-left {
    position: absolute;
    width: 100%;
    padding-left: 40px;
  }
  .add-item {
    margin-top: 10px;
  }
  .flag-nav {
    display: none;
  }
  .mobile_btn {
    color: #333;
    cursor: pointer;
    display: block;
    font-size: 24px;
    height: 60px;
    left: 0;
    line-height: 60px;
    padding: 0 15px;
    position: absolute;
    text-align: center;
    top: 0;
    z-index: 10;
  }
  #toggle_btn {
    display: none;
  }
  .top-nav-search {
    display: none;
  }
  .login-wrapper .loginbox .login-left {
    padding: 80px 50px;
    width: 50%;
  }
  .login-wrapper .loginbox .login-right {
    padding: 50px;
    width: 50%;
  }
  .sidebar {
    margin-left: -225px;
    width: 225px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1041;
  }
  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .login-wrapper .loginbox {
    padding: 30px;
  }
  .login-wrapper .loginbox h3 {
    font-size: 22px;
  }
  .modal.contentmodal .modal-content h3 {
    font-size: 18px;
  }
  .error-box h2 {
    font-size: 28px;
  }
  .error-box p {
    font-size: 16px;
  }
  .darkmode .mobile_btn {
    color: #97a2d2;
  }
}
@media (max-width: 767.98px) {
  body {
    font-size: 0.9375rem;
  }
  h1,
  .h1 {
    font-size: 2rem;
  }
  h2,
  .h2 {
    font-size: 1.75rem;
  }
  h3,
  .h3 {
    font-size: 1.25rem;
  }
  h4,
  .h4 {
    font-size: 1.125rem;
  }
  h5,
  .h5 {
    font-size: 16px;
  }
  h6,
  .h6 {
    font-size: 0.875rem;
  }
  .header .has-arrow .dropdown-toggle:after {
    display: none;
  }
  .user-menu.nav > li > a > span:not(.user-img) {
    display: none;
  }
  .navbar-nav .open .dropdown-menu {
    float: left;
    position: absolute;
  }
  .navbar-nav.user-menu .open .dropdown-menu {
    left: auto;
    right: 0;
  }
  .header .header-left {
    padding: 0 15px;
  }
  .header .header-left .logo {
    display: none;
  }
  .header-left .logo.logo-small {
    display: inline-block;
    padding-left: 40px;
  }
  .login-wrapper .loginbox .login-left {
    display: none;
  }
  .login-wrapper .loginbox {
    max-width: 450px;
    min-height: unset;
  }
  .login-wrapper .loginbox .login-right {
    float: none;
    padding: 1.875rem;
    width: 100%;
  }
  .top-action-left .float-left {
    float: none !important;
  }
  .top-action-left .btn-group {
    margin-bottom: 15px;
  }
  .top-action-right {
    text-align: center;
  }
  .top-action-right a.btn.btn-white {
    margin-bottom: 15px;
  }
  .mail-sent-time {
    float: left;
    margin-top: 10px;
    width: 100%;
  }
  .login-wrapper .loginbox .login-right h1 {
    font-size: 22px;
  }
  .error-box h2 {
    font-size: 22px;
  }
  .error-box .btn {
    font-size: 15px;
    min-width: 150px;
    padding: 8px 20px;
  }
  .dash-count {
    font-size: 16px;
    display: inline-block;
  }
  .dash-widget-header {
    display: block;
  }
  .nav {
    display: block;
  }
  .timeline > li > .timeline-badge {
    top: 16px;
    left: 15px;
    margin-left: 0;
  }
  ul.timeline > li > .timeline-panel {
    float: right;
    width: calc(100% - 90px);
  }
  .timeline > li > .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
  }
  ul.timeline > li > .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
  }
  ul.timeline:before {
    left: 40px;
  }
  .card .card-header .card-title {
    margin-bottom: 8px;
  }
  .notifications .noti-content {
    width: 300px;
  }
  .user-menu .dropdown-menu {
    min-width: 300px;
  }
  .main-drop .dropdown-menu {
    min-width: 163px;
  }
  .list-links li a {
    padding: 10px;
    font-size: 14px;
  }
  .modal.contentmodal .media img {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }
  .modal.contentmodal .media h3 {
    font-size: 16px;
  }
  .modal.contentmodal .media .doc-info p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 120px;
  }
  .payment-item .head-title {
    text-align: left;
    margin-bottom: 10px;
  }
  .preference-option p {
    margin-bottom: 10px;
  }
  .account-subtitle {
    margin-bottom: 20px;
  }
  .login-wrapper .loginbox h3 {
    margin-bottom: 10px;
  }
  .login-bg {
    display: none;
  }
  .social-login > a {
    margin-right: 15px;
  }
  .about-author {
    align-items: start;
  }
  .nav-tabs.menu-tabs .nav-link {
    display: block;
    text-align: center;
  }
  .nav-tabs.menu-tabs .nav-item {
    display: block;
  }
  .card .card-header .card-title {
    font-size: 16px;
  }
  .links-cont .form-group {
    margin-bottom: 10px;
  }
  .settings-form .trash {
    right: 0;
    margin-bottom: 10px;
  }
}
@media (max-width: 575.98px) {
  .card {
    margin-bottom: 0.9375rem;
  }
  .page-wrapper > .content {
    padding: 0.9375rem;
  }
  .chat-window {
    margin-bottom: 0.9375rem;
  }
  .card-body {
    padding: 1.25rem;
  }
  .card-header {
    padding: 0.75rem 1.25rem;
  }
  .card-footer {
    padding: 0.75rem 1.25rem;
  }
  .page-header {
    margin-bottom: 0.9375rem;
  }
  .pagination-lg .page-link {
    font-size: 1.2rem;
    padding: 0.5rem 0.625rem;
  }
  .invoice-details {
    float: left;
    text-align: left;
  }
  .page-header .breadcrumb {
    display: none;
  }
  .card-header .form-custom {
    margin: 0 0 10px !important;
  }
  .custom-list {
    width: 100%;
    flex-direction: column;
  }
  .card-header .form-custom,
  .card-header .multipleSelection,
  .card-header .SortBy {
    width: 100%;
  }
  .list-links li {
    width: 100%;
    margin-right: 0;
    text-align: center;
  }
  .list-links li a {
    width: 100%;
  }
  .profile-detail {
    flex-direction: column;
    text-align: center;
  }
  .profile-detail .pro-name {
    padding-left: 0;
  }
  .profile-detail .edit-pro {
    margin-left: 0;
  }
  .app-listing {
    flex-direction: column;
  }
  .app-listing .import-list,
  .app-listing .SortBy {
    margin-left: 0;
  }
  .pagination-tab .page-item {
    padding: 0 2px;
  }
}
